declare const window: any;
declare const $: any;
const _window = window;

export const _base = function () {
	window.cp = 1024; // computer min 1024
	window.tablet = 768; // table min 768

	// moble < 768
	window.delHandle = function (list: any, str: any, idxActived: number = -1) {
		if (typeof str == "string") {
			list.forEach(function (item: HTMLElement, idx: number) {
				if (idx !== idxActived) {
					item.classList.remove(str);
				}
			});
		}
	};

	window.isNonNegativeNumber = function (value: any) {
		// Sử dụng parseInt để chuyển đổi giá trị thành số nguyên
		var numberValue = parseInt(value, 10);

		// Kiểm tra xem giá trị đã được chuyển đổi thành số thành công
		if (!isNaN(numberValue) && numberValue >= 0) {
			return true;
		}
		return false;
	}

	window.handeleClickGroup = function (group: any, idxActived: number, callBack: Function = function () { }) {
		var item: HTMLElement;
		group.forEach(function (gpItem: HTMLElement, idxGp: number) {
			if (idxGp === idxActived) {
				gpItem.classList.add('is-showed');
				gpItem.style.display = "";
				gpItem.style.opacity = "";
				gpItem.style.visibility = "";
				gpItem.style.animation = ".8s isShowed cubic-bezier(0.075, 0.82, 0.165, 1) forwards";

				item = gpItem;
			} else {
				gpItem.classList.remove('is-showed');
				gpItem.style.display = "none";
				gpItem.style.opacity = "0";
				gpItem.style.visibility = "hidden";

				gpItem.style.animation = "";
			}
		})

		callBack(item);
	}

	window.handleHiddenItem = function (item: any, callBack: Function = function () { }) {
		item.classList.remove('is-showed');
		item.style.display = "none";
		item.style.opacity = "0";
		item.style.visibility = "hidden";
		item.style.animation = "";

		callBack(item);
	}
	window.handleVisiblleItem = function (item: any, callBack: Function = function () { }) {
		item.classList.add('is-showed');
		item.style.display = "";
		item.style.animation = ".8s isShowed cubic-bezier(0.075, 0.82, 0.165, 1) forwards";
		callBack(item);
	}

	window.getAllQueryParam = function () {
		var searchParams = new URLSearchParams(window.location.search);
		var params = {}
		searchParams.forEach(function (value, key) {
			params = {
				...params,
				[key]: value
			}
		});

		return params;
	}
	window.getQueryParam = function (name: any) {
		var searchParams = new URLSearchParams(window.location.search);
		return searchParams.get(name);
	}

	window.updateURLParams = function (paramsToUpdate: any) {
		// Lấy tất cả các tham số truy vấn từ URL hiện tại
		var searchParams = new URLSearchParams(window.location.search);

		// Lặp qua các tham số cần cập nhật
		for (var key in paramsToUpdate) {
			if (paramsToUpdate.hasOwnProperty(key)) {
				searchParams.set(key, paramsToUpdate[key]);
			}
		}

		// Tạo URL mới với các thay đổi
		var newURL = window.location.pathname + '?' + searchParams.toString();

		console.log(newURL);

		// Cập nhật URL trên trình duyệt
		window.history.pushState({ paramsToUpdate }, '', newURL);
	}

	window.removeQueryParam = function (paramName: any) {
		// Lấy tất cả các tham số truy vấn từ URL hiện tại
		var searchParams = new URLSearchParams(window.location.search);

		// Loại bỏ tham số truy vấn
		searchParams.delete(paramName);

		// Tạo URL mới với các thay đổi
		var newURL;
		if (searchParams.toString() !== '') {
			newURL = window.location.pathname + '?' + searchParams.toString();
		} else {
			newURL = window.location.pathname;
		}

		// Cập nhật URL trên trình duyệt
		window.location.replace(newURL);

		// window.history.pushState({ path: newURL }, '', newURL);
	}

	window.scrollToID = function (idstring: any) {
		var element;
		if (typeof (idstring) === "object") {
			element = idstring;
		} else {
			element = document.getElementById(idstring + "");
		}

		var topFeatured = 0;
		var featuredItem = <HTMLElement>element;
		if (featuredItem) {
			var parentFeatured = <HTMLElement>featuredItem.offsetParent;
			var count = 0;
			while (parentFeatured.nodeName !== "BODY" && count < 100) {
				topFeatured += parentFeatured.offsetTop;
				parentFeatured = <HTMLElement>parentFeatured.offsetParent;
				count++;
			}
			topFeatured += featuredItem.offsetTop;
		}

		window.scrollTo({
			top: topFeatured,
			left: 0,
			behavior: "smooth",
		});
	}

	// mouse in seemore box
	window.mouseBoxHandle = function (lsName: string, queryMouseHandle: string) {
		// var boxes = document.querySelectorAll(listItem);
		var mousePos: any;
		var checked = false;

		function handleMouseMove(event: any) {
			var dot, eventDoc, doc, body, pageX, pageY;
			event = event || window.event; // IE-ism
			// If pageX/Y aren't available and clientX/Y are,
			// calculate pageX/Y - logic taken from jQuery.
			// (This is to support old IE)
			if (event.pageX == null && event.clientX != null) {
				eventDoc = (event.target && event.target.ownerDocument) || document;
				doc = eventDoc.documentElement;
				body = eventDoc.body;

				event.pageX = event.clientX +
					(doc && doc.scrollLeft || body && body.scrollLeft || 0) -
					(doc && doc.clientLeft || body && body.clientLeft || 0);
				event.pageY = event.clientY +
					(doc && doc.scrollTop || body && body.scrollTop || 0) -
					(doc && doc.clientTop || body && body.clientTop || 0);
			}

			mousePos = {
				x: event.clientX,
				y: event.clientY
			};

			let target = event.target;
			// console.log(boxes);
			if (target && target.parentElement && target.parentElement.classList.contains(lsName)) {
				checked = true;
			}
			else {
				do {
					target = target.parentElement;

					if (target && target.parentElement == null) {
						checked = false;
						break;
					}

					if (target && target.parentElement && target.parentElement.classList.contains(lsName)) {
						checked = true;
						return;
					}
				} while (target.parentElement !== null)
				checked = false;
			}
		}

		function getMousePosition() {
			var pos = mousePos;
			if (!pos) {
				// We haven't seen any movement yet
			}
			else {
				// Use pos.x and pos.y
				var divMouse = <HTMLElement>document.querySelector(queryMouseHandle);
				divMouse.style.top = mousePos.y + "px";
				divMouse.style.left = mousePos.x + "px";
				if (checked) {
					divMouse.classList.add('visible');
				} else {
					divMouse.classList.remove('visible');
				}
			}
		}
		document.onmousemove = handleMouseMove;
		setInterval(getMousePosition);
	};

	window.mouseBoxItemHandle = function (elementItem: HTMLElement, queryMouseHandle: string) {
		// var boxes = document.querySelectorAll(listItem);
		var mousePos: any;
		var checked = false;

		function handleMouseMove(event: any) {
			var dot, eventDoc, doc, body, pageX, pageY;
			event = event || window.event; // IE-ism
			// If pageX/Y aren't available and clientX/Y are,
			// calculate pageX/Y - logic taken from jQuery.
			// (This is to support old IE)
			if (event.pageX == null && event.clientX != null) {
				eventDoc = (event.target && event.target.ownerDocument) || document;
				doc = eventDoc.documentElement;
				body = eventDoc.body;

				event.pageX = event.clientX +
					(doc && doc.scrollLeft || body && body.scrollLeft || 0) -
					(doc && doc.clientLeft || body && body.clientLeft || 0);
				event.pageY = event.clientY +
					(doc && doc.scrollTop || body && body.scrollTop || 0) -
					(doc && doc.clientTop || body && body.clientTop || 0);
			}

			mousePos = {
				x: event.clientX,
				y: event.clientY
			};

			let target = event.target;
			checked = true;

			// console.log(boxes);
			// if (target && target.parentElement && target.parentElement.classList.contains('')){
			// 	checked = true;	
			// }
			// else{
			// 	do{
			// 		target = target.parentElement;

			// 		if (target && target.parentElement == null){
			// 			checked = false;
			// 			break;
			// 		}

			// 		if (target && target.parentElement && target.parentElement.classList.contains('')){
			// 			checked = true;
			// 			return;
			// 		}
			// 	}while(target.parentElement !== null)
			// 	checked = false;
			// }
		}

		function handleMouseOut(event: any) {
			checked = false;
		}



		function getMousePosition() {
			var pos = mousePos;
			if (!pos) {
				// We haven't seen any movement yet
			}
			else {
				// Use pos.x and pos.y
				var divMouse = <HTMLElement>elementItem.querySelector(queryMouseHandle);
				divMouse.style.top = mousePos.y + "px";
				divMouse.style.left = mousePos.x + "px";
				if (checked) {
					divMouse.classList.add('visible');
				} else {
					divMouse.classList.remove('visible');
				}
			}
		}

		elementItem.onmousemove = handleMouseMove;
		elementItem.onmouseout = handleMouseOut;
		setInterval(getMousePosition);
	};

	window.copyMaker = function (copyText: string, callback: Function = function () { }) {
		try {
			const input = document.createElement("input");
			input.value = copyText;
			input.setAttribute('type', 'text');
			input.style.height = "0";
			input.style.opacity = "0";
			let body = document.querySelector('body');
			body.appendChild(input);

			input.select();

			document.execCommand("copy");

			input.remove();

			callback('success');

			// copyElement.addEventListener("mouseover", function(e){
			// 	copyElement.setAttribute("tooltip", textElement);
			// });
		}
		catch {
			callback('error');
		}
	}

	window.addToastMsg = function (status: string, message: string) {
		let toastMsg = <HTMLElement>document.querySelector('.toast-msg');
		if (!toastMsg) {
			let eToast = document.createElement('ul');
			eToast.classList.add('toast-msg');

			toastMsg = eToast;

			document.body.appendChild(toastMsg);
		}

		let item = document.createElement('li');

		item.classList.add(status);

		item.innerHTML = `
			<p>${message}</p>
		`;
		let timeMsg = 3000;
		let timeDurationMsg = 600;

		const iChild = 5;

		item.style.transitionDuration = (timeDurationMsg / 1000) + "s";

		item.addEventListener('click', function () {
			let promise = new Promise(function (res, rej) {
				item.classList.remove('active');
				res(item);
			})
			promise.then(function (value) {
				setTimeout(function () {
					item.remove();
				}, timeDurationMsg)
			})
		})

		// time message
		let timeout = new Promise(function (resole, reject) {
			try {
				if (!item) {
					throw 'Not item message!';
				}
				setTimeout(function () {
					item.classList.add('active');
					resole(item);
				}, 0)
			}
			catch (err) {
				reject(err)
			}
		})

		timeout.then(function (item: HTMLElement) {
			return new Promise(function (resole, reject) {
				setTimeout(function () {
					item.classList.remove('active');
					resole(item);
				}, timeMsg)
			})
		}).then(function (value: HTMLElement) {
			setTimeout(function () {
				value.remove();

				// remove Toast list
				if (toastMsg.children.length === 0) {
					toastMsg.remove();
				}
			}, timeDurationMsg)
		})
			.catch(function (error) {
				console.error(`${error}`)
			})

		if (toastMsg.children.length + 1 > iChild) {
			toastMsg.children[0].remove();
		}

		toastMsg.appendChild(item);
	}

	window.targetHandle = function (target: any = document, checkCondition: Function, callBack: Function) {
		target.addEventListener('click', function (e: any) {
			let target = e.target;

			if (checkCondition(target) === true) {
				return;
			}

			target = target.parentElement;
			do {
				if (checkCondition(target) === true) {
					return;
				}
				target = target.parentElement;
			} while (target.parentElement);

			callBack();
		})
	}

	function closeHandle(popup: any, callBack: Function = function () { }) {
		popup.classList.remove('is-showed');

		callBack(popup);
	}

	window.popupHandle = function (target: any, callBackClose: Function = function () { return "" }) {
		try {
			if (typeof target === 'string') {
				target = document.querySelector(`${target}`);
			}

			const clickHandleTarget = function (e: any) {
				e.preventDefault();
				popup.classList.add('is-showed');
			}

			target.removeEventListener('click', clickHandleTarget);
			target.addEventListener('click', clickHandleTarget);

			let popup = document.querySelector(`[data-popup-section="${target.getAttribute('data-popup')}"]`) || document.getElementById(`${target.getAttribute('data-popup')}`);
			if (!popup) {
				throw `Not found popup: ${target.getAttribute('data-popup')}`;
			}

			if (popup.classList.contains('initialized')) {
				return;
			}

			let closes = popup.querySelectorAll('.close');

			const evtClose = function (e: any) {
				e.preventDefault();
				closeHandle(popup, callBackClose);
			}

			closes.forEach(function (close) {
				close.addEventListener('click', evtClose);
			})

			let cancels = popup.querySelectorAll('.cancel');
			cancels.forEach(function (cancel: HTMLElement) {
				cancel.addEventListener('click', function (e: any) {
					e.preventDefault();
					closeHandle(popup);
				})
			})

			// _window.targetHandle(popup, function(e:HTMLElement){
			// 	if (e.classList.contains('wrapper')){
			// 		return true;
			// 	}
			// 	return false;
			// }, function(){
			// 	closeHandle(popup, callBackClose);
			// })

			// let modal = popup.querySelector('.modal');
			popup.addEventListener('click', function (e) {
				e.stopPropagation();
				if (e.target === popup) {
					closeHandle(popup, callBackClose);
				}
			})

			popup.classList.add("initialized");
		}
		catch (err) {
			console.error(err);
		}
	}

	window.initPopupHandle = function (target: any, callBackClose: Function = function () { return "" }) {
		try {
			let popup = target;
			if (typeof target === "string") {
				popup = document.querySelector(target);
			}
			if (!popup) {
				throw `Not found popup: ${target.getAttribute('data-popup')}`;
			}

			if (popup.classList.contains('initialized')) {
				popup.classList.add('opened');
				return;
			}

			let close = popup.querySelector('.close');
			if (close) {
				close.addEventListener('click', function (e: any) {
					e.preventDefault();
					closeHandle(popup, callBackClose);
				});
			}

			let cancel = popup.querySelector('.cancel');
			if (cancel) {
				cancel.querySelector('a').addEventListener('click', function (e: any) {
					e.preventDefault();
					closeHandle(popup, callBackClose);
				})
			}

			_window.targetHandle(popup, function (e: HTMLElement) {
				if (e.classList.contains('wrapper')) {
					return true;
				}
				return false;
			}, function () {
				closeHandle(popup, callBackClose);
			})

			// target.addEventListener('click', function(e:any){
			// 	e.preventDefault();
			// })

			popup.classList.add('opened');
			popup.classList.add("initialized");
		}
		catch (err) {
			console.error(err);
		}
	}

	window.backToTop = (target: HTMLElement, strQuery: string) => {
		const backtotop = <HTMLElement>document.querySelector(strQuery);
		if (backtotop && target) {

			const handleScroll = () => {
				let top = target.getBoundingClientRect().top;
				if (top <= window.innerHeight + 200) {
					backtotop.classList.add("showed");
					return true;
				}

				backtotop.classList.remove('showed');
				return false;
			}

			handleScroll();

			let timeout: any;
			window.addEventListener('scroll', () => {
				clearTimeout(timeout);
				timeout = setTimeout(handleScroll, 200);
			})

			const handleClick = (e: any) => {
				e.preventDefault();

				window.scrollTo({
					top: 0,
					left: 0,
					behavior: "smooth"
				});
			}
			backtotop.onclick = handleClick;
		}
	}

	window.stepNumber = function (finalNumber: number, duration = 2000, start = 0, callback: Function) {
		const startTime = performance.now() + start;

		function step(currentTime: any) {
			const elapsedTime = currentTime - startTime;

			if (elapsedTime > duration) {
				callback(finalNumber);
			} else {
				const rate = elapsedTime / duration
				const currentNumber = Math.round(rate * finalNumber)
				callback(currentNumber)
				requestAnimationFrame(step)
			}
		}

		requestAnimationFrame(step);
	}

	window.optsw = {
		"loopSlide": {
			slidesPerView: "auto",
			loop: true,
			speed: 1600,
			autoplay: {
				delay: 0,
				disableOnInteraction: false,
			},
		}
	}

	window.sliderThumbs = function (swiperSlider: any, swiperThumbs: any, callback: Function = function () { }) {
		if (swiperSlider.slides.length !== swiperThumbs.slides.length) {
			console.error('Insufficient slides!');
			return;
		}

		const defaultSlide = 0;

		swiperSlider.slideTo(defaultSlide);
		swiperThumbs.slideTo(defaultSlide);
		callback();

		swiperSlider.on('slideChange', function () {
			let activeIdx = swiperSlider.activeIndex;
			swiperThumbs.slideTo(activeIdx);
			callback();
		});

		swiperThumbs.on('slideChange', function () {
			let activeIdx = swiperThumbs.activeIndex;
			swiperSlider.slideTo(activeIdx);
			callback();
		});
	}
};
