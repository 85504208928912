declare const window: any;

export const initPage = function () {
	let pagination: any;

	pagination = function (opts: any) {
		this.options = Object.assign(pagination.defaults, opts);
		this.init(opts);
	};

	pagination.prototype.init = function (opts: any) {
		this.options = opts ? Object.assign(pagination.defaults, opts) : opts;
		var plugin = this;
		let options = plugin.options;

		// let paramItem = options.param;
		// queryPagination: string, queryBox: string

		var pages = typeof (options.selector) === "object" ? options.selector : <HTMLElement>document.querySelector(`${options.selector}`);
		var totalBox = typeof (options.items) === "object" ? options.items : document.querySelectorAll(options.items);

		pages.innerHTML = "";

		var totalPage = Math.ceil(totalBox.length / options.page);
		plugin.activeBox(totalBox, options.page, 1);

		if (!pages || !totalBox || totalPage < 1) {
			return;
		}

		plugin.results = plugin.options.callback();

		if (plugin.results) {
			let type = plugin.results.type;
			let params = plugin.results.params;

			if (type === "clicked" && params) {
				plugin.itemActive = 1;
				plugin.paginationCreate(
					pages,
					totalBox,
					plugin.itemActive,
					totalPage,
					options.page
				);
				window.updateURLParams(params);
			}
			else if (type === "popstate" && params) {
				var value = window.getQueryParam(plugin.options.param);
				var idxPage = window.isNonNegativeNumber(value) ? parseInt(value) : 1;

				plugin.itemActive = idxPage;
				plugin.paginationCreate(
					pages,
					totalBox,
					plugin.itemActive,
					totalPage,
					plugin.options.page,
				);
			} else {
				var value = window.getQueryParam(plugin.options.param);
				var idxPage = window.isNonNegativeNumber(value) ? parseInt(value) : 1;

				if (idxPage > totalPage) {
					idxPage = 1;
					window.removeQueryParam(plugin.options.param);
				}
				plugin.itemActive = idxPage;
				plugin.paginationCreate(
					pages,
					totalBox,
					plugin.itemActive,
					totalPage,
					options.page
				);
			}
		} else {
			var value = window.getQueryParam(plugin.options.param);
			var idxPage = window.isNonNegativeNumber(value) ? parseInt(value) : 1;
			if (idxPage > totalPage) {
				idxPage = 1;
				window.removeQueryParam(plugin.options.param);
			}

			plugin.itemActive = idxPage;

			plugin.paginationCreate(
				pages,
				totalBox,
				plugin.itemActive,
				totalPage,
				options.page
			);
		}
	};

	pagination.prototype.paginationCreate = function (
		objClass: any,
		totalBox: any,
		liActive: number,
		totalPage: number,
		itemPages: number
	) {
		// Math.ceil(totalBox.length / 5)
		var plugin = this;
		objClass.innerHTML = this.createPagination(totalPage, liActive);

		var strIcon = `<svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M1 11.8615L6 6.86148L1 1.86148" stroke="#989898" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>`;

		plugin.activeBox(
			totalBox,
			itemPages,
			plugin.itemActive
		);

		if (totalPage == 1) {
			return;
		}

		// thay đổi kí tự next và prev
		plugin.changeIcon(objClass, "next", strIcon);
		plugin.changeIcon(objClass, "prev", strIcon);


		// click button
		plugin.clickButton(
			objClass,
			totalBox,
			totalPage,
			itemPages
		);
	};

	pagination.prototype.createPagination = function (
		totalPages: any,
		page: any,
		zero = false
	) {
		let strStart = `<li class="prev"><a href="javascript:void(0)">prev</a></li>`;
		let strList = "";

		// khi tổng số page nhỏ hơn 5
		if (totalPages == 1) {
			return "";
		} else if (totalPages < 5) {
			for (var i = 1; i <= totalPages; i++) {
				let strI = i < 10 ? `${zero === true ? '0' : ''}${i}` : `${i}`;
				if (i == page) {
					strList += `<li class="is-actived" data-number="${i}"><a href="javascript:void(0)">${strI}</a></li>`;
				} else {
					strList += `<li data-number="${i}"><a href="javascript:void(0)">${strI}</a></li>`;
				}
			}
		} else if (
			(page <= 3 || page >= totalPages - 2) &&
			page > 0 &&
			page <= totalPages
		) {
			// trường hợp active tạo thành 1 2 3 4 ... 20
			if (page <= 3) {
				if (page == 3 && totalPages == 5) {
					for (var i = 1; i <= totalPages; i++) {
						let strI = i < 10 ? `${zero === true ? '0' : ''}${i}` : `${i}`;
						if (i == page) {
							strList += `<li class="is-actived" data-number="${i}"><a href="javascript:void(0)">${strI}</a></li>`;
						} else {
							strList += `<li data-number="${i}"><a href="javascript:void(0)">${strI}</a></li>`;
						}
					}
				} else {
					var visiblePage = page + 1;
					if (page == 1) {
						visiblePage = 3;
					}
					for (var i = 1; i <= visiblePage; i++) {
						let strI = i < 10 ? `${zero === true ? '0' : ''}${i}` : `${i}`;

						if (i == page) {
							strList += `<li class="is-actived" data-number="${i}"><a href="javascript:void(0)">${strI}</a></li>`;
						} else {
							strList += `<li data-number="${i}"><a href="javascript:void(0)">${strI}</a></li>`;
						}
					}
					let strTotal = totalPages < 10 ? `${zero === true ? '0' : ''}${totalPages}` : `${totalPages}`;
					strList += `<li class="dot">...</li>`;
					strList += `<li data-number="${totalPages}"><a href="javascript:void(0)">${strTotal}</a></li>`;
				}
			}
			// trường hợp active tạo thành 1 ... 18 19 20
			else {
				strList += `<li data-number="1"><a href="javascript:void(0)">${zero === true ? '0' : ''}1</a></li>`;
				strList += `<li class="dot">...</li>`;

				var visiblePage2 = page - 1;
				if (page == totalPages) {
					visiblePage2 = totalPages - 2;
				}

				for (var i = visiblePage2; i <= totalPages; i++) {
					let strI = i < 10 ? `${zero === true ? '0' : ''}${i}` : `${i}`;

					if (i == page) {
						strList += `<li class="is-actived" data-number="${i}"><a href="javascript:void(0)">${strI}</a></li>`;
					} else {
						strList += `<li data-number="${i}"><a href="javascript:void(0)">${strI}</a></li>`;
					}
				}
			}
		}
		// trường hợp active tạo thành 1 ... 7 8 9 ... 20
		else if (page > 3 && page < totalPages - 2) {
			strList += `<li data-number="1"><a href="javascript:void(0)">${zero === true ? '0' : ''}1</a></li>`;
			strList += `<li class="dot">...</li>`;
			for (var i = page - 1; i <= page + 1; i++) {
				let strI = i < 10 ? `${zero === true ? '0' : ''}${i}` : `${i}`;

				if (i == page) {
					strList += `<li class="is-actived" data-number="${i}"><a href="javascript:void(0)">${strI}</a></li>`;
				} else {
					strList += `<li data-number="${i}"><a href="javascript:void(0)">${strI}</a></li>`;
				}
			}
			let strTotal = totalPages < 10 ? `${zero === true ? '0' : ''}${totalPages}` : `${totalPages}`;
			strList += `<li class="dot">...</li>`;
			strList += `<li data-number="${totalPages}"><a href="javascript:void(0)">${strTotal}</a></li>`;
		} else {
			console.log("error pagination!!!");
			return "";
		}

		let strEnd = `<li class="next"><a href="javascript:void(0)">next</a></li>`;

		return `<ul>${strStart.concat(strList, strEnd)}</ul>`;
	};

	pagination.prototype.activeBox = function (
		totalBox: any,
		itemPages: number,
		liActive: any,
	) {
		let _this = this;
		totalBox.forEach(function (item: HTMLElement) {
			item.style.display = "";
		});

		totalBox.forEach(function (item: HTMLElement, index: number, pageActive: number = liActive, lens: number = totalBox.length) {
			var itemPage = Math.ceil((index + 1) / itemPages);

			if (itemPage !== liActive) {
				item.style.display = "none";
			} else {
				// item.style.setProperty('dt-i', `${numberActive + 1}`);
				item.style.display = "";
				// _this.options.callback(item, index, liActive, lens);
			}
		});
	};

	pagination.prototype.clickButton = function (
		objClass: any,
		totalBox: any,
		totalPage: number,
		itemPages: number
	) {
		var plugin = this;
		plugin.itemActive = plugin.itemActive || 1;

		let liBtns = objClass.getElementsByTagName("li");

		// let itemActive = 0;

		// lấy giá trị được active
		for (let i = 0; i < liBtns.length; i++) {
			let item = <HTMLElement>liBtns[i];
			if (item.classList.contains("is-actived")) {
				plugin.itemActive = parseInt(
					item.getAttribute("data-number") || "0",
				);
			}
		}

		// Không cho click vào button Next or Prev nếu active gần nó
		plugin.hiddenNextPrev(
			objClass,
			plugin.itemActive,
			totalPage,
		);

		for (let i = 0; i < liBtns.length; i++) {
			let item = liBtns[i];

			item.onclick = function () {
				// let classItem = this.getAttribute('class');
				let dataItem = parseInt(this.getAttribute("data-number"));

				if (
					this.classList.contains("btn-hidden") ||
					dataItem == plugin.itemActive
				) {
					return;
				}
				if (
					this.classList.contains("next") &&
					plugin.itemActive < totalPage
				) {
					plugin.itemActive = plugin.itemActive + 1;
				} else if (
					this.classList.contains("prev") &&
					plugin.itemActive > 1
				) {
					plugin.itemActive = plugin.itemActive - 1;
				} else {
					if (!dataItem) {
						return;
					}
					plugin.itemActive = dataItem;
				}

				plugin.hiddenNextPrev(objClass, plugin.itemActive, totalPage);
				plugin.paginationCreate(
					objClass,
					totalBox,
					plugin.itemActive,
					totalPage,
					itemPages
				);
				plugin.activeBox(
					totalBox,
					itemPages,
					plugin.itemActive
				);

				if (plugin.options.scroll) {
					plugin.scrollToTop(
						plugin.options.scroll.id,
						plugin.options.scroll.header
					);
				}

				if (plugin.options && plugin.options.param) {
					if (plugin.results && plugin.results.params) {
						plugin.results.params = {
							...plugin.results.params,
							[plugin.options.param]: plugin.itemActive
						}
					} else {
						plugin.results = {
							params: {
								[plugin.options.param]: plugin.itemActive
							}
						}
					}
					let params = plugin.results.params;

					if (params) {
						window.updateURLParams(params);
					}
				}
			};
		}
	};

	pagination.prototype.scrollToTop = function (
		strId: string,
		queryHeader: string = ""
	) {
		var plugin = this;

		if (document.getElementById(strId)) {
			var topFeatured = 0;
			var featuredItem = <HTMLElement>document.getElementById(strId);
			if (featuredItem) {
				var parentFeatured = <HTMLElement>featuredItem.offsetParent;
				var count = 0;
				while (parentFeatured.nodeName !== "BODY" && count < 100) {
					topFeatured += parentFeatured.offsetTop;
					parentFeatured = <HTMLElement>parentFeatured.offsetParent;
					count++;
				}
				topFeatured += featuredItem.offsetTop;
			}

			// // Trừ đi thanh Header
			// var hHeader = document.querySelector(queryHeader);
			// if (hHeader){
			//   var scroll = hHeader.getElementsByClassName('scroll');
			//   if (scroll[0].classList.contains('active')){
			//     topFeatured -= scroll[0].clientHeight;
			//   }
			//   else if (hHeader){
			//     topFeatured -= hHeader.clientHeight;
			//   }
			// }

			window.scrollTo({
				top: topFeatured,
				left: 0,
				behavior: "smooth",
			});
		}
	};

	pagination.prototype.changeIcon = function (
		objClass: any,
		strClass: string,
		strIcon: string
	) {
		var classStrChange = objClass.getElementsByClassName(strClass);
		var innerStrClass = <HTMLElement>classStrChange[0];

		innerStrClass.innerHTML = strIcon;
	};

	pagination.prototype.hiddenNextPrev = function (
		objClass: any,
		itemActive: number,
		totalPage: number
	) {
		let liBtns = objClass.getElementsByTagName("li");

		let itemNext;
		let itemPrev;

		// lấy giá trị được active
		for (let i = 0; i < liBtns.length; i++) {
			let item = <HTMLElement>liBtns[i];
			if (item.classList.contains("next")) {
				itemNext = item;
			} else if (item.classList.contains("prev")) {
				itemPrev = item;
			}
		}

		// Không cho click vào button Next or Prev nếu active gần nó
		if (totalPage === 1) {
			itemPrev.classList.add("btn-hidden");
			itemNext.classList.add("btn-hidden");
		} else if (itemActive === 1) {
			itemPrev.classList.add("btn-hidden");
			itemNext.classList.remove("btn-hidden");
		} else if (itemActive === totalPage) {
			itemPrev.classList.remove("btn-hidden");
			itemNext.classList.add("btn-hidden");
		} else {
			itemPrev.classList.remove("btn-hidden");
			itemNext.classList.remove("btn-hidden");
		}
	};

	pagination.prototype.delete = function () {
		// console.log('deleted');

	}

	pagination.defaults = {
		id: "", //
		param: "",
		selector: "", // Class pagination
		items: "",
		page: 0,
		scroll: {
			id: "",
		},
		callback: function () {
			return;
		}
	};

	window.pagination = pagination;
};
