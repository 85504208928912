declare var Swiper: any;

function ourTechSwiper() {
	var swiper = new Swiper('.our-tech .swiper-container', {
		slidesPerView: 3,
		centeredSlides: true,
		spaceBetween: 20,
		loop: true,
		pagination: {
			el: ".our-tech .swiper-pagination",
			clickable: true,
		},
		speed: 2000,
		autoplay: {
			delay: 4000,
			disableOnInteraction: false,
		},
	});
}
function topFunction() {
	// var mybutton = document.getElementById("myBtn");
	// 	window.scrollTo({
	// 		behavior : 'smooth',
	// 		top:100,
	// 	})
	window.scrollTo(500, 0)
}

var swiper2 = new Swiper('.mattress-recommend__wrapper .right-recommend .swiper-container', {
	slidesPerView: 1,

	loop: true,
	navigation: {
		nextEl: ".swiper-button-next",
		prevEl: ".swiper-button-prev",
	},
});

var swiper3 = new Swiper(".swiper-btn__nav .mySwiper", {
	spaceBetween: 10,
	slidesPerView: 4,
	watchSlidesVisibility: true,
	watchSlidesProgress: true,
	navigation: {
		nextEl: ".swiper-button-next",
		prevEl: ".swiper-button-prev",
	},
});


var swiperSlogan = new Swiper(".sloganSwiper", {
	spaceBetween: 10,
	slidesPerView: 1,
	speed: 1000,
	autoplay: {
		delay: 3000,
		disableOnInteraction: false,
	},
});

function swiperDetailProducts() {
	var swiper4 = new Swiper(".details-infor__wrapper .mySwiper2", {
		zoom: true,
		spaceBetween: 10,

		thumbs: {
			swiper: swiper3,
		},
	});
}

function bannerHeight() {
	var hBanner = <HTMLElement>document.querySelector('main');
	if (hBanner) {
		var hHeader = document.querySelector('.header_wapper');
		hBanner.style.marginTop = hHeader.clientHeight + "px";
	}
}

function swiperWholesaleService() {
	// var nextBtn = <HTMLElement> document.querySelector('.wholesale .wholesale-service-left .swiper-button-next');
	// var prevBtn = <HTMLElement> document.querySelector('.wholesale .wholesale-service-left .swiper-button-prev');
	// if (nextBtn && prevBtn){
	// 	var blockSwiper = document.querySelector('.wholesale .wholesale-service-left .images');
	// 	var titleSwiper = document.querySelector('.wholesale .wholesale-service-left .title');
	// 	if (blockSwiper && titleSwiper){
	// 		var heightSwiper = blockSwiper.clientHeight;
	// 		var heightTitle = titleSwiper.clientHeight;
	// 		var height = (heightSwiper / 2) + heightTitle;
	// 		nextBtn.style.top = height + "px";
	// 		prevBtn.style.top = height + "px";
	// 	}
	// }

	new Swiper('.wholesale .wholesale-service-left .swiper-container', {
		spaceBetween: 10,
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},

		pagination: {
			el: ".wholesale .wholesale-service-left .swiper-pagination",
			clickable: true,
		},
	})
}

// Thêm mũi tên sau mỗi button có query .btn .btn-arrow
function fBtnArrow() {
	var btnArrows = document.querySelectorAll('.btn .btn-arrow');
	btnArrows.forEach(function (btn) {
		btn.innerHTML += ' ' + `<svg xmlns="http://www.w3.org/2000/svg" width="12.922" height="12.791" viewBox="0 0 12.922 12.791"><path id="Path_126" data-name="Path 126" d="M0,0,5.188,5.33S10.377-.136,10.377,0" transform="translate(6.592 11.377) rotate(-90)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/><path id="Path_127" data-name="Path 127" d="M0,0,5.188,5.33S10.377-.136,10.377,0" transform="translate(1.411 11.377) rotate(-90)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg>`;
	});
}

// active icon feature
function activeIconFeatures() {
	if (document.querySelector('.featured')) {
		var icons = document.querySelectorAll('.featured .icons .icons-item');
		icons.forEach(function (icon) {
			icon.addEventListener('click', function () {
				// console.log([icon])
				var widthSpan = 220;
				var iconHTML = <HTMLElement>icon;
				var iconParent = iconHTML.offsetParent;
				// console.log(iconHTML.offsetLeft + 220);
				// console.log(iconParent.clientWidth);

				if (iconHTML.offsetLeft + 220 >= iconParent.clientWidth) {
					if (icon.classList.contains('active-right') || icon.classList.contains('active')) {
						icon.classList.remove('active');
						icon.classList.remove('active-right');
					}
					else {
						icons.forEach(function (e) {
							e.classList.remove('active');
							e.classList.remove('active-right');
						})

						icon.classList.add('active-right');
					}
				}
				else {
					if (icon.classList.contains('active')) {
						icon.classList.remove('active');
						icon.classList.remove('active-right');
					}
					else {
						icons.forEach(function (e) {
							e.classList.remove('active');
							e.classList.remove('active-right');
						})

						icon.classList.add('active');
					}
				}
			})
		})
	}
}

function activeCartShop(evt: any) {
	var cartShop = document.querySelector('.header-top-section-right .shop-cart');
	if (cartShop) {
		var evtTarget = evt.target;
		// Kiem tra co click vao cartshop ko? Neu co thi ko lam gi` ca
		do {
			if (evtTarget == cartShop) {
				var cartShopIcon = document.querySelector('.header-top-section-right .shop-cart__icon');
				if (!cartShopIcon) {
					return;
				}
				return;
			}
			evtTarget = evtTarget.parentNode;
		} while (evtTarget);

		// Neu ko thi se remove class active
		var cartShopIcon = document.querySelector('.header-top-section-right .shop-cart__icon');
		cartShopIcon.classList.remove('active');
	}
}

function activeHamberger(evt: any) {
	var hamberger = document.querySelector('.header-top-section-right__ham');
	if (hamberger) {
		var evtTarget = evt.target;
		// Kiem tra co click vao hamberger ko? Neu co thi ko lam gi` ca
		do {
			if (evtTarget == hamberger) {
				var hambergerImg = document.querySelector('.header-top-section-right__ham .hamberger__img');
				if (!hambergerImg) {
					return;
				}
				return;
			}
			evtTarget = evtTarget.parentNode;
		} while (evtTarget);

		// Neu ko thi se remove class active
		var hambergerImg = document.querySelector('.header-top-section-right__ham .hamberger__img');
		hambergerImg.classList.remove('active');
	}
}

function activeUser(evt: any) {
	var userBtn = document.querySelector('.header-top-section-right .user');
	var loginPopup = document.querySelector('.form-dealer__popup');
	if (!loginPopup) {
		return;
	}
	var userForm = loginPopup.querySelector('.form-alert__popup--wrapper');
	var closeForm = loginPopup.querySelector('.btn-close');

	if (userBtn) {
		var evtTarget = evt.target;
		do {
			if (evtTarget == closeForm) {
				break;
			}
			if (evtTarget == userBtn || evtTarget == userForm) {
				if (!loginPopup) {
					return;
				}
				return;
			}
			evtTarget = evtTarget.parentNode;
		} while (evtTarget);

		userBtn.classList.remove('active');
		loginPopup.classList.remove('active');
	}


	// old
	// var userBtn = document.querySelector('.header-top-section-right .user');
	// if (userBtn) {
	// 	var evtTarget = evt.target;
	// 	do {
	// 		if (evtTarget == userBtn) {
	// 			var userBtnImg = document.querySelector('.header-top-section-right .user__img');
	// 			if (!userBtnImg) {
	// 				return;
	// 			}
	// 			return;
	// 		}
	// 		evtTarget = evtTarget.parentNode;
	// 	} while (evtTarget);

	// 	var userBtnImg = document.querySelector('.header-top-section-right .user__img');
	// 	userBtnImg.classList.remove('active');
	// }
}

function activeFind(evt: any) {
	var findBtn = document.querySelector('.header-top-section-right .find');
	if (findBtn) {
		var evtTarget = evt.target;
		do {
			if (evtTarget == findBtn) {
				var findBtnImg = document.querySelector('.header-top-section-right .find__img');
				if (!findBtnImg) {
					return;
				}
				return;
			}
			evtTarget = evtTarget.parentNode;
		} while (evtTarget);

		var findBtnImg = document.querySelector('.header-top-section-right .find__img');
		findBtnImg.classList.remove('active');
	}
}

function toggleHeader() {
	// toggle CartShop
	var cartShop = document.querySelector('.header-top-section-right .shop-cart__icon');
	cartShop.addEventListener('click', function () {
		cartShop.classList.toggle('active');
	})

	// close Cart Shop
	var closeShopCart = document.querySelector('.header-top-section-right .shop-cart-box--close');
	if (closeShopCart) {
		closeShopCart.addEventListener('click', function () {
			var cartShop = document.querySelector('.header-top-section-right .shop-cart__icon');
			if (cartShop) {
				cartShop.classList.remove('active');
			}
		})
	}

	// toggle Hamberger
	var hamberger = document.querySelector('.header-top-section-right__ham .hamberger__img');
	if (hamberger) {
		hamberger.addEventListener('click', function () {
			hamberger.classList.toggle('active');
		});
	}

	// toggle User
	var userBtn = document.querySelector('.header_wapper .user__img');
	if (userBtn) {
		userBtn.addEventListener('click', function (evt) {
			userBtn.classList.toggle('active');

			var formLogin = document.querySelector('.form-dealer__popup');
			if (formLogin) {
				formLogin.classList.add('active');
			}
		})
	}

	// toggle search
	var findBtn = document.querySelector('.header_wapper .find__img');
	if (findBtn) {
		findBtn.addEventListener('click', function (evt) {
			findBtn.classList.toggle('active');
		})
	}
}

function headerUserHandle() {
	if (document.querySelector('.header_wapper')) {
		document.addEventListener('click', function (evt) {
			activeUser(evt);
			activeHamberger(evt);
			activeCartShop(evt);
			activeFind(evt);
		})
		toggleHeader();

		var boxes = document.querySelectorAll('.hamberger-box__item');
		if (boxes) {
			boxes.forEach(function (box) {
				box.addEventListener('click', function () {
					if (box.classList.contains('active')) {
						box.classList.remove('active');
					}
					else {
						boxes.forEach(function (box) {
							box.classList.remove('active');
						})
						box.classList.add('active');
					}
				})
			})
		}

		// close hamberger
		var outMenuBar = document.querySelector('.hamberger');
		if (outMenuBar) {
			outMenuBar.addEventListener('click', function () {
				document.querySelector('.header-top-section-right__ham').classList.remove('active');
			})
		}

		// // number cart
		var numberCart = <HTMLElement>document.querySelector('.header-top-section-right .shop-cart__icon');
		if (numberCart) {
			var value = numberCart.getAttribute('data-number');

			if (typeof (parseInt(value)) === typeof (1)) {
				if (parseInt(value) <= 0) {
					if (!numberCart.classList.contains('after--hidden')) {
						numberCart.classList.add('after--hidden');
					}
				}
			}
			else {
				if (!numberCart.classList.contains('after--hidden')) {
					numberCart.classList.add('after--hidden');
				}
			}
		}


		// handle click link
		var loginFormUser = document.getElementById('header-user');
		var loginFormDealer = document.getElementById('header-dealer');
		var registerFormUser = document.querySelector('.form-content__register--user');
		var registerFormDealer = document.querySelector('.form-content__register--dealer');
		var loginFormNav = document.querySelectorAll('.form-content__register--nav');
		var forgotFormNav = document.querySelectorAll('.form-content__forgot--nav');
		var shopNowButton = document.querySelectorAll('.shop-now__button');
		var shopCartButton = document.querySelector('.shop-cart-box__button');

		// login
		if (loginFormUser) {
			loginFormUser.addEventListener('click', function (e) {
				e.preventDefault();
				removeActiveForm();
				addActiveForm('form-login__wrapper');
			})
		}
		if (loginFormDealer) {
			loginFormDealer.addEventListener('click', function (e) {
				e.preventDefault();
				removeActiveForm();
				addActiveForm('form-login__wrapper');
			})
		}

		// register
		if (registerFormUser) {
			registerFormUser.addEventListener('click', function (e) {
				e.preventDefault();
				removeActiveForm();
				addActiveForm('form-user__wrapper');
			})
		}

		if (registerFormDealer) {
			registerFormDealer.addEventListener('click', function (e) {
				e.preventDefault();
				removeActiveForm();
				addActiveForm('form-dealer__wrapper');
			})
		}

		// nav login
		loginFormNav.forEach(function (nav) {
			// console.log(loginFormNav);
			nav.addEventListener('click', function (e) {
				e.preventDefault();
				removeActiveForm();
				addActiveForm('form-login__wrapper');
			})
		})

		// forgot password
		forgotFormNav.forEach(function (forget) {
			forget.addEventListener('click', function (e) {
				e.preventDefault();
				removeActiveForm();
				addActiveForm('form-forgot__wrapper');
			})
		})

		// shop cart
		if (shopCartButton) {
			shopCartButton.addEventListener('click', function (e) {
				e.preventDefault();
				removeActiveForm();
				addActiveForm('form-custom__wrapper');
			})
		}

		shopNowButton.forEach(function (sbtn) {
			sbtn.addEventListener('click', function (e) {
				e.preventDefault();
				removeActiveForm();
				addActiveForm('form-custom__wrapper');
			})
		})

		// Clicked close form
		var closePopups = document.querySelectorAll('.loginform__close');
		if (closePopups) {
			closePopups.forEach(function (pop) {
				pop.addEventListener('click', function (e) {
					removeActiveForm();
				})
			})
		}
	}
}

// function submitSearch() {
// 	var smSearch = document.getElementById('formSearch');
// 	if(smSearch){
// 		smSearch.addEventListener('submit', function (e) {
// 			e.preventDefault();
// 		})
// 	}
// }

function addActiveForm(queryStringId: any) {
	var active = document.getElementById(queryStringId);
	if (active) {
		if (!active.classList.contains('active')) {
			active.classList.add('active');
		}
	}

	var body = document.querySelector('body');
	if (body) {
		body.style.height = "100vh";
		body.style.overflow = "hidden";
	}

	if (queryStringId === "form-custom__wrapper") {
		cartHandle();
	}
}

function parentAttribute(e: any, strData: any) {
	var parent = <HTMLElement>e;
	var dataProduct = "";
	if (parent) {
		var count = 1;
		if (parent.nodeName) {
			while (!(parent.nodeName && parent.nodeName === "LI") && count < 100 && parent) {
				parent = parent.parentElement;
			}
			if (parent) {
				if (parent.nodeName === "LI") {
					dataProduct = parent.getAttribute(strData);
					return dataProduct;
				}
			}
			else if (count >= 100) {
				return "";
			}
		}
	}
}

function getTitleProduct(e: any) {
	var parent = <HTMLElement>e;
	if (parent) {
		var count = 1;
		while (!(parent.nodeName === "LI") && count < 100 && parent) {
			parent = parent.parentElement;
		}
		if (parent) {
			if (parent.nodeName === "LI") {
				var title;
				parent.childNodes.forEach(function (child) {
					var childItems = <HTMLElement>child;
					if (childItems.children && childItems.children.item(1)) {
						var titleItem = childItems.children.item(1);
						if (titleItem.children) {
							title = titleItem.children.item(0).innerHTML;
						}
					}
				})
				return title;
			}
		}
		else if (count >= 100) {
			return "";
		}
	}
	return "";
}


function loadCartHeader() {
	// Get api
	var shopBox = document.querySelector('.header_wapper .header-top-section-right .shop-cart');
	if (shopBox) {
		var xhttp = new XMLHttpRequest();
		xhttp.onreadystatechange = function () {
			if (this.readyState == 4) {
				shopBox.innerHTML = this.responseText;

				//load js
				headerUserHandle();
			}
			else {
				// console.log('failed to LoadCartHeader!');
				return;
			}
		};
		// xhttp.open("GET", "https://jsonplaceholder.typicode.com/posts", true);
		xhttp.open("GET", '/load-cart-header', true);
		xhttp.send();
	}
}

function loadCartForm() {
	// Get api
	var cartForm = document.querySelector('#form-custom__wrapper .form-flex .form-flex--right');
	if (cartForm) {
		var xhttp = new XMLHttpRequest();
		xhttp.onreadystatechange = function () {
			if (this.readyState == 4) {
				cartForm.innerHTML = this.responseText;

				//load js
				cartHandle();
			}
			else {
				// console.log('failed to LoadCartForm!');
				return;
			}
		};
		// xhttp.open("GET", "https://jsonplaceholder.typicode.com/posts", true);
		xhttp.open("GET", '/load-cart-form', true);
		xhttp.send();
	}
}

// reload lại form trong cart shop
function reloadForm() {
	loadCartHeader();
	loadCartForm();
}


function deleteCartHandle() {
	var delProducts = document.querySelectorAll('.product__item--close .product__item--span');
	if (delProducts) {
		delProducts.forEach(function (del) {
			var delClick = <HTMLInputElement>del;
			delClick.onclick = function () {
				var title = getTitleProduct(this); // dùng khi muốn điền thêm title vào phần cofirm delete
				var confirmDel = confirm(`Are you sure you want to delete?`);
				if (confirmDel) {
					var id = parentAttribute(this, "data-product");
					var idProperty = parentAttribute(this, "data-property");
					var idOrder = document.getElementById('cartForm');
					var data = {};

					if (idOrder && id && idOrder.getAttribute('data-order')) {
						var dataOrder = idOrder.getAttribute('data-order');
						data = {
							IdProduct: parseInt(id),
							IdOrder: parseInt(dataOrder),
							IdProperty: parseInt(idProperty),
						};
					}
					// var id = del.getAttribute('data-product');

					// Get api
					var xhttp = new XMLHttpRequest();
					xhttp.onreadystatechange = function () {
						if (this.readyState == 4 && this.status == 200) {
							reloadForm();
						}
						else {
							// console.log('failed to delete product in the cart!');
							return false;
						}
					};
					// xhttp.open("GET", "https://jsonplaceholder.typicode.com/posts", true);
					xhttp.open("POST", '/post-cart-delete', true);
					xhttp.setRequestHeader("Content-type", "application/json;charset=UTF-8");
					xhttp.send(JSON.stringify(data));
				}
			}
			// del.addEventListener('click', function(e){
			// 	var title = getTitleProduct(this); // dùng khi muốn điền thêm title vào phần cofirm delete
			// 	var confirmDel = confirm(`Are you sure you want to delete?`);
			// 	if (confirmDel){
			// 		var id = parentAttribute(this, "data-product");
			// 		var idProperty = parentAttribute(this, "data-property");
			// 		var idOrder = document.getElementById('cartForm');
			// 		var data = {};

			// 		if (idOrder && id && idOrder.getAttribute('data-order')){
			// 			var dataOrder = idOrder.getAttribute('data-order');
			// 			data = {
			// 				IdProduct: parseInt(id),
			// 				IdOrder: parseInt(dataOrder),
			// 				IdProperty: parseInt(idProperty),
			// 			};
			// 		}
			// 		// var id = del.getAttribute('data-product');

			// 		// Get api
			// 		var xhttp = new XMLHttpRequest();
			// 		xhttp.onreadystatechange = function () {
			// 			if (this.readyState == 4 && this.status == 200) {
			// 				reloadForm();
			// 			}
			// 			else {
			// 				console.log('failed to delete product in the cart!');
			// 				return;
			// 			}
			// 		};
			// 		// xhttp.open("GET", "https://jsonplaceholder.typicode.com/posts", true);
			// 		xhttp.open("POST", '/post-cart-delete', true);
			// 		xhttp.setRequestHeader("Content-type", "application/json;charset=UTF-8");
			// 		xhttp.send(JSON.stringify(data));
			// 	}
			// })
		})
	}
}


function numberCartHandle() {
	var plusProducts = document.querySelectorAll('.product__item--quanlity .product__item--plus');
	var minusProducts = document.querySelectorAll('.product__item--quanlity .product__item--minus');

	if (plusProducts) {
		plusProducts.forEach(function (plus) {

			var prevElement = plus.previousElementSibling;
			var minusElement = prevElement.previousElementSibling;
			if (!(prevElement && prevElement.classList.contains('product__item--calc'))) {
				prevElement = plus.nextElementSibling;
				minusElement = prevElement.nextElementSibling;
			}
			if (prevElement && prevElement.classList.contains('product__item--calc')) {
				var numberCurrent = parseInt(prevElement.innerHTML);

				var item = <HTMLElement>plus;
				if (numberCurrent >= 100) {
					item.style.pointerEvents = "none";
				}
				else {
					item.style.pointerEvents = "auto";
				}
			}

			var temp = 0;

			var timerId = setTimeout(function () {

			}, 0);

			var thisPlus = <HTMLElement>plus;

			function calcPlusAPI() {

				var id = parentAttribute(thisPlus, "data-product");
				var idOrder = document.getElementById('cartForm');
				var data = {};

				if (idOrder && id && idOrder.getAttribute('data-order')) {
					var dataOrder = idOrder.getAttribute('data-order');
					data = {
						IdProduct: parseInt(id),
						IdOrder: parseInt(dataOrder),
						sumPlus: temp
					};
				}

				// console.log(data)
				temp = 0;

				// Get api
				var xhttp = new XMLHttpRequest();
				xhttp.onreadystatechange = function () {
					if (this.readyState == 4 && this.status == 200) {
						reloadForm();
					}
					else {
						// console.log('failed to plus product in the cart!');
						return;
					}
				};
				// xhttp.open("GET", "https://jsonplaceholder.typicode.com/posts", true);
				xhttp.open("POST", `/post-cart-plus`, true);
				xhttp.setRequestHeader("Content-type", "application/json;charset=UTF-8");
				xhttp.send(JSON.stringify(data));


			}

			function debounceFunction(func: any, delay: any) {
				clearTimeout(timerId);
				timerId = setTimeout(func, delay);
			}

			var plusClick = <HTMLInputElement>plus;
			plusClick.onclick = function () {
				if (prevElement && prevElement.classList.contains('product__item--calc')) {
					var numberCurrent = parseInt(prevElement.innerHTML);

					var numberPast = numberCurrent + 1;
					prevElement.innerHTML = numberPast + "";

					var item = <HTMLElement>this;
					if (numberPast >= 100) {
						item.style.pointerEvents = "none";
					}
					else {
						item.style.pointerEvents = "auto";
					}


					var minus = <HTMLElement>minusElement;
					if (numberPast <= 1) {
						minus.style.pointerEvents = "none";
					}
					else {
						minus.style.pointerEvents = "auto";
					}

					temp++;
					// thisPlus = this;
					debounceFunction(calcPlusAPI, 200);
				}
			}
			// plus.addEventListener('click', function(){
			// 	if (prevElement && prevElement.classList.contains('product__item--calc')){
			// 		var numberCurrent = parseInt(prevElement.innerHTML);

			// 		var numberPast = numberCurrent + 1;
			// 		prevElement.innerHTML = numberPast + "";

			// 		var item = <HTMLElement> this;
			// 		if (numberPast >= 100){
			// 			item.style.pointerEvents = "none";
			// 		}
			// 		else{
			// 			item.style.pointerEvents = "auto";
			// 		}


			// 		var minus = <HTMLElement>minusElement;
			// 		if (numberPast <= 1){
			// 			minus.style.pointerEvents = "none";
			// 		}
			// 		else{
			// 			minus.style.pointerEvents = "auto";
			// 		}

			// 		temp++;
			// 		// thisPlus = this;
			// 		debounceFunction(calcPlusAPI, 200);

			// 		// var id = parentAttribute(this, "data-product");
			// 		// var idOrder = document.getElementById('cartForm');
			// 		// var data = {};

			// 		// if (idOrder && id && idOrder.getAttribute('data-order')){
			// 		// 	var dataOrder = idOrder.getAttribute('data-order');
			// 		// 	data = {
			// 		// 		IdProduct: parseInt(id),
			// 		// 		IdOrder: parseInt(dataOrder)
			// 		// 	};
			// 		// }

			// 		// // Get api
			// 		// var xhttp = new XMLHttpRequest();
			// 		// xhttp.onreadystatechange = function () {
			// 		// 	if (this.readyState == 4 && this.status == 200) {
			// 		// 		reloadForm();
			// 		// 	}
			// 		// 	else {
			// 		// 		console.log('failed to plus product in the cart!');
			// 		// 		return;
			// 		// 	}
			// 		// };
			// 		// // xhttp.open("GET", "https://jsonplaceholder.typicode.com/posts", true);
			// 		// xhttp.open("POST", `/post-cart-plus`, true);
			// 		// xhttp.setRequestHeader("Content-type", "application/json;charset=UTF-8");
			// 		// xhttp.send(JSON.stringify(data));
			// 	}
			// })
		})
	}
	if (minusProducts) {
		minusProducts.forEach(function (minus) {
			var prevElement = minus.nextElementSibling;
			var plusElement = prevElement.nextElementSibling;
			if (!(prevElement && prevElement.classList.contains('product__item--calc'))) {
				prevElement = minus.previousElementSibling;
				plusElement = prevElement.previousElementSibling;
			}
			if (prevElement && prevElement.classList.contains('product__item--calc')) {
				var numberCurrent = parseInt(prevElement.innerHTML);

				var item = <HTMLElement>minus;
				if (numberCurrent <= 1) {
					item.style.pointerEvents = "none";
				}
				else {
					item.style.pointerEvents = "auto";
				}
			}

			var temp = 0;

			var timerId = setTimeout(function () {

			}, 0);

			var thisMinus = <HTMLElement>minus;

			function calcMinusAPI() {
				var id = parentAttribute(thisMinus, "data-product");
				var idOrder = document.getElementById('cartForm');
				var data = {};

				if (idOrder && id && idOrder.getAttribute('data-order')) {
					var dataOrder = idOrder.getAttribute('data-order');
					data = {
						IdProduct: parseInt(id),
						IdOrder: parseInt(dataOrder),
						sumMinus: temp
					};
				}

				// console.log(data);
				temp = 0;

				// Get api
				var xhttp = new XMLHttpRequest();
				xhttp.onreadystatechange = function () {
					if (this.readyState == 4 && this.status == 200) {
						reloadForm();
					}
					else {
						// console.log('failed to minus product in the cart!');
						return false;
					}
				};
				// xhttp.open("GET", "https://jsonplaceholder.typicode.com/posts", true);
				xhttp.open("POST", `/post-cart-minus`, true);
				xhttp.setRequestHeader("Content-type", "application/json;charset=UTF-8");
				xhttp.send(JSON.stringify(data));
			}

			function debounceFunction(func: any, delay: any) {
				clearTimeout(timerId);
				timerId = setTimeout(func, delay);
			}
			var minusClick = <HTMLInputElement>minus;
			minusClick.onclick = function () {
				if (prevElement && prevElement.classList.contains('product__item--calc')) {
					var numberCurrent = parseInt(prevElement.innerHTML);
					var numberPast = numberCurrent - 1;
					prevElement.innerHTML = numberPast + "";

					var item = <HTMLElement>this;
					if (numberPast <= 1) {
						item.style.pointerEvents = "none";
					}
					else {
						item.style.pointerEvents = "auto";
					}

					var item2 = <HTMLElement>plusElement;
					if (numberPast >= 100) {
						item2.style.pointerEvents = "none";
					}
					else {
						item2.style.pointerEvents = "auto";
					}
					// thisMinus = this;
					debounceFunction(calcMinusAPI, 200);

					// var id = parentAttribute(this, "data-product");
					// var idOrder = document.getElementById('cartForm');
					// var data = {};

					// if (idOrder && id && idOrder.getAttribute('data-order')){
					// 	var dataOrder = idOrder.getAttribute('data-order');
					// 	data = {
					// 		IdProduct: parseInt(id),
					// 		IdOrder: parseInt(dataOrder),
					// 	};
					// }
					// // Get api
					// var xhttp = new XMLHttpRequest();
					// xhttp.onreadystatechange = function () {
					// 	if (this.readyState == 4 && this.status == 200) {
					// 		reloadForm();
					// 	}
					// 	else {
					// 		console.log('failed to minus product in the cart!');
					// 		return;
					// 	}
					// };
					// // xhttp.open("GET", "https://jsonplaceholder.typicode.com/posts", true);
					// xhttp.open("POST", `/post-cart-minus`, true);
					// xhttp.setRequestHeader("Content-type", "application/json;charset=UTF-8");
					// xhttp.send(JSON.stringify(data));
				}
			}
			// minus.addEventListener('click', function(){
			// 	if (prevElement && prevElement.classList.contains('product__item--calc')){
			// 		var numberCurrent = parseInt(prevElement.innerHTML);
			// 		var numberPast = numberCurrent - 1;
			// 		prevElement.innerHTML = numberPast + "";

			// 		var item = <HTMLElement> this;
			// 		if (numberPast <= 1){
			// 			item.style.pointerEvents = "none";
			// 		}
			// 		else{
			// 			item.style.pointerEvents = "auto";
			// 		}

			// 		var item2 = <HTMLElement> plusElement;
			// 		if (numberPast >= 100){
			// 			item2.style.pointerEvents = "none";
			// 		}
			// 		else{
			// 			item2.style.pointerEvents = "auto";
			// 		}
			// 		// thisMinus = this;
			// 		debounceFunction(calcMinusAPI, 200);

			// 		// var id = parentAttribute(this, "data-product");
			// 		// var idOrder = document.getElementById('cartForm');
			// 		// var data = {};

			// 		// if (idOrder && id && idOrder.getAttribute('data-order')){
			// 		// 	var dataOrder = idOrder.getAttribute('data-order');
			// 		// 	data = {
			// 		// 		IdProduct: parseInt(id),
			// 		// 		IdOrder: parseInt(dataOrder),
			// 		// 	};
			// 		// }
			// 		// // Get api
			// 		// var xhttp = new XMLHttpRequest();
			// 		// xhttp.onreadystatechange = function () {
			// 		// 	if (this.readyState == 4 && this.status == 200) {
			// 		// 		reloadForm();
			// 		// 	}
			// 		// 	else {
			// 		// 		console.log('failed to minus product in the cart!');
			// 		// 		return;
			// 		// 	}
			// 		// };
			// 		// // xhttp.open("GET", "https://jsonplaceholder.typicode.com/posts", true);
			// 		// xhttp.open("POST", `/post-cart-minus`, true);
			// 		// xhttp.setRequestHeader("Content-type", "application/json;charset=UTF-8");
			// 		// xhttp.send(JSON.stringify(data));
			// 	}
			// })
		})
	}
}

function deleteAllCartHandle() {
	var btnDelAll = <HTMLInputElement>document.querySelector('.form-cart-table__footer .footer-submit input[type="button"]');
	btnDelAll.onclick = function (e) {
		e.preventDefault();
		// Get api
		var idOrder = document.getElementById('cartForm');
		var data = {};

		if (idOrder && idOrder.getAttribute('data-order')) {
			var dataOrder = idOrder.getAttribute('data-order');
			data = {
				IdOrder: parseInt(dataOrder)
			};
		}
		var xhttp = new XMLHttpRequest();
		xhttp.onreadystatechange = function () {
			if (this.readyState == 4 && this.status == 200) {
				reloadForm();
			}
			else {
				// console.log('failed to delete all product in the cart!');
				return false;
			}
		};
		// xhttp.open("GET", "https://jsonplaceholder.typicode.com/posts", true);
		xhttp.open("POST", `/post-delete-all-cart`, true);
		xhttp.setRequestHeader("Content-type", "application/json;charset=UTF-8");
		xhttp.send(JSON.stringify(data));
	}

	// var btnDelAll = document.querySelector('.form-cart-table__footer .footer-submit input[type="button"]');
	// btnDelAll.addEventListener('click', function(e){
	// 	e.preventDefault();
	// 	// Get api
	// 	var idOrder = document.getElementById('cartForm');
	// 	var data = {};

	// 	if (idOrder && idOrder.getAttribute('data-order')){
	// 		var dataOrder = idOrder.getAttribute('data-order');
	// 		data = {
	// 			IdOrder: parseInt(dataOrder)
	// 		};
	// 	}
	// 	var xhttp = new XMLHttpRequest();
	// 	xhttp.onreadystatechange = function () {
	// 		if (this.readyState == 4 && this.status == 200) {
	// 			reloadForm();
	// 		}
	// 		else {
	// 			console.log('failed to delete all product in the cart!');
	// 			return;
	// 		}
	// 	};
	// 	// xhttp.open("GET", "https://jsonplaceholder.typicode.com/posts", true);
	// 	xhttp.open("POST", `/post-delete-all-cart`, true);
	// 	xhttp.setRequestHeader("Content-type", "application/json;charset=UTF-8");
	// 	xhttp.send(JSON.stringify(data));
	// })
}

function submitFormCart() {
	// var submitBtnCart = document.getElementById('formCartLayout');

	var cpn1 = <HTMLInputElement>document.getElementById('companyName1');
	var cpn2 = <HTMLInputElement>document.getElementById('companyName2');
	var cpn3 = <HTMLInputElement>document.getElementById('companyName3');
	var cpn4 = <HTMLInputElement>document.getElementById('companyName4');
	var cpn5 = <HTMLInputElement>document.getElementById('companyName5');

	var note = <HTMLInputElement>document.getElementById('noteFormCartTable');

	if (cpn1 && cpn2 && cpn3 && cpn4 && cpn5) {
		cpn1.onchange = function (e) {
			cpn1.classList.remove('error');
			cpn1.classList.remove('success');
		}
		cpn2.onchange = function (e) {
			cpn2.classList.remove('error');
			cpn2.classList.remove('success');
		}
		cpn3.onchange = function (e) {
			cpn3.classList.remove('error');
			cpn3.classList.remove('success');
		}
		cpn4.onchange = function (e) {
			cpn4.classList.remove('error');
			cpn4.classList.remove('success');
		}
		cpn5.onchange = function (e) {
			cpn5.classList.remove('error');
			cpn5.classList.remove('success');
		}
	}

	// if (cpn1 && cpn2 && cpn3 && cpn4 && cpn5){
	// 	cpn1.addEventListener('change', function(e){
	// 		this.classList.remove('error');
	// 		this.classList.remove('success');
	// 	})
	// 	cpn2.addEventListener('change', function(e){
	// 		this.classList.remove('error');
	// 		this.classList.remove('success');
	// 	})
	// 	cpn3.addEventListener('change', function(e){
	// 		this.classList.remove('error');
	// 		this.classList.remove('success');
	// 	})
	// 	cpn4.addEventListener('change', function(e){
	// 		this.classList.remove('error');
	// 		this.classList.remove('success');
	// 	})
	// 	cpn5.addEventListener('change', function(e){
	// 		this.classList.remove('error');
	// 		this.classList.remove('success');
	// 	})
	// }

	var idOrder = document.getElementById('cartForm');

	var submitBtnCart = <HTMLInputElement>document.getElementById('formCartLayout');

	submitBtnCart.onsubmit = function (e) {
		e.preventDefault();

		if (!(idOrder && idOrder.getAttribute('data-order'))) {
			return false;
		}

		var dataOrder = idOrder.getAttribute('data-order');

		var unCheck = false;
		if (!(cpn1.value.trim())) {
			cpn1.classList.add('error');
			unCheck = true;
		}
		else {
			cpn1.classList.add('success');
		}

		if (!(cpn2.value.trim())) {
			cpn2.classList.add('error');
			unCheck = true;
		}
		else {
			cpn2.classList.add('success');
		}


		if (!(cpn3.value.trim())) {
			cpn3.classList.add('error');
			unCheck = true;
		}
		else {
			var sdt = cpn3.value.trim();
			var regexSdt = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
			if (sdt.match(regexSdt)) {
				cpn3.classList.add('success');
			}
			else {
				cpn3.classList.add('error');
				unCheck = true;
			}
		}
		if (!(cpn4.value.trim())) {
			cpn4.classList.add('error');
			unCheck = true;
		}
		else {
			var email = cpn4.value.trim();
			var regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			if (email.match(regexEmail)) {
				cpn4.classList.add('success');
			}
			else {
				cpn4.classList.add('error');
				unCheck = true;
			}
		}

		if (!(cpn5.value.trim())) {
			cpn5.classList.add('error');
			unCheck = true;
		}
		else {
			cpn5.classList.add('success');
		}

		if (unCheck) {
			return false;
		}

		var data = {
			IdOrder: parseInt(dataOrder),

			companyName1: cpn1.value,
			companyName2: cpn2.value,
			companyName3: cpn3.value,
			companyName4: cpn4.value,
			companyName5: cpn5.value,

			note: note.value,
		}

		var xhttp = new XMLHttpRequest();
		xhttp.onreadystatechange = function () {
			if (this.readyState == 4 && this.status == 200) {
				reloadForm();
				var mess = JSON.parse(this.responseText);
				if (mess && mess.Code) {
					if (mess.Code == 400) {
						alert(mess.Message);
						return;
					}
					else if (mess.Code === 200) {
						alert(mess.Message);
						return;
					}
				}
				else {
					// console.log(this.responseText);
				}
			}
			else {
				// console.log('failed to submit product in the cart!');
				return;
			}
		};

		xhttp.open("POST", `/post-submit-cart`, true);
		xhttp.setRequestHeader("Content-type", "application/json;charset=UTF-8");
		xhttp.send(JSON.stringify(data));
	}

	// submitBtnCart.addEventListener('submit', function(e){
	// 	e.preventDefault();

	// 	if (!(idOrder && idOrder.getAttribute('data-order'))){
	// 		return false;
	// 	}

	// 	var dataOrder = idOrder.getAttribute('data-order');

	// 	var unCheck = false;
	// 	if (!(cpn1.value.trim())){
	// 		cpn1.classList.add('error');
	// 		unCheck = true;
	// 	}
	// 	else{
	// 		cpn1.classList.add('success');
	// 	}

	// 	if (!(cpn2.value.trim())){
	// 		cpn2.classList.add('error');
	// 		unCheck = true;
	// 	}
	// 	else{
	// 		cpn2.classList.add('success');
	// 	}


	// 	if (!(cpn3.value.trim())){
	// 		cpn3.classList.add('error');
	// 		unCheck = true;
	// 	}
	// 	else{
	// 		var sdt = cpn3.value.trim();
	// 		var regexSdt = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
	// 		if(sdt.match(regexSdt)){
	// 			cpn3.classList.add('success');
	// 		}
	// 		else{
	// 			cpn3.classList.add('error');
	// 			unCheck = true;
	// 		}
	// 	}
	// 	if (!(cpn4.value.trim())){
	// 		cpn4.classList.add('error');
	// 		unCheck = true;
	// 	}
	// 	else{
	// 		var email = cpn4.value.trim();
	// 		var regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	// 		if(email.match(regexEmail)){
	// 			cpn4.classList.add('success');
	// 		}
	// 		else{
	// 			cpn4.classList.add('error');
	// 			unCheck = true;
	// 		}
	// 	}

	// 	if (!(cpn5.value.trim())){
	// 		cpn5.classList.add('error');
	// 		unCheck = true;
	// 	}
	// 	else{
	// 		cpn5.classList.add('success');
	// 	}

	// 	if (unCheck){
	// 		return false;
	// 	}

	// 	var data = {
	// 		IdOrder: parseInt(dataOrder),

	// 		companyName1: cpn1.value,
	// 		companyName2: cpn2.value,
	// 		companyName3: cpn3.value,
	// 		companyName4: cpn4.value,
	// 		companyName5: cpn5.value,

	// 		note: note.value,
	// 	}

	// 	console.log(data);

	// 	var xhttp = new XMLHttpRequest();
	// 	xhttp.onreadystatechange = function () {
	// 		if (this.readyState == 4 && this.status == 200) {
	// 			reloadForm();
	// 			var mess = JSON.parse(this.responseText);
	// 			console.log(mess);
	// 			if (mess.Code == 400){
	// 				alert(mess.Message);
	// 			}
	// 			else{
	// 				alert(this.responseText);
	// 			}
	// 		}
	// 		else if (this.status == 400){
	// 			alert("400: " + this.responseText);
	// 			return;
	// 		}
	// 		else {
	// 			reloadForm();
	// 			console.log('failed to submit product in the cart!');
	// 			return;
	// 		}
	// 	};

	// 	xhttp.open("POST", `/post-submit-cart`, true);
	// 	xhttp.setRequestHeader("Content-type", "application/json;charset=UTF-8");
	// 	xhttp.send(JSON.stringify(data));
	// })
}

function cartHandle() {
	// handle delete
	deleteCartHandle();
	numberCartHandle();
	deleteAllCartHandle();
	submitFormCart();
}

function removeActiveForm() {
	var login = document.getElementById('form-login__wrapper');
	var registerUser = document.getElementById('form-user__wrapper');
	var registerDealer = document.getElementById('form-dealer__wrapper');
	var custom = document.getElementById('form-custom__wrapper');
	var forgot = document.getElementById('form-forgot__wrapper');

	var popupLogin = document.querySelector('.form-dealer__popup');

	if (login) {
		if (login.classList.contains('active')) {
			login.classList.remove('active');
		}
	} if (registerUser) {
		if (registerUser.classList.contains('active')) {
			registerUser.classList.remove('active');
		}
	} if (registerDealer) {
		if (registerDealer.classList.contains('active')) {
			registerDealer.classList.remove('active');
		}
	} if (custom) {
		if (custom.classList.contains('active')) {
			custom.classList.remove('active');
		}
	} if (forgot) {
		if (forgot.classList.contains('active')) {
			forgot.classList.remove('active');
		}
	} if (popupLogin) {
		if (popupLogin.classList.contains('active')) {
			popupLogin.classList.remove('active');
		}
	}

	var body = document.querySelector('body');
	if (body) {
		body.style.height = "";
		body.style.overflow = "";
	}
}

function footerHandle() {
	if (document.querySelector('.footer__wrapper')) {
		// change input
		var inputEmail = document.getElementById('emailFooter');
		inputEmail.addEventListener('change', function () {
			var info = document.getElementById('formEmailFooterInfo');
			info.innerHTML = "";
			info.classList.remove('success');
			info.classList.remove('error');
		})

		// submit form email
		var formEmail = document.getElementById('formEmailFooter');
		formEmail.addEventListener('submit', function (e) {
			e.preventDefault();
			var email = <HTMLInputElement>document.getElementById('emailFooter');
			var data = { email: email.value };

			// Get api
			var xhttp = new XMLHttpRequest();
			xhttp.onreadystatechange = function () {
				var info = document.getElementById('formEmailFooterInfo');

				if (this.readyState == 4 && this.status == 200) {
					if (this.responseText) {
						var object = JSON.parse(this.responseText);
						if (object && object.Code) {
							if (object.Code === 400) {
								if (object && object.Message) {
									info.classList.add('error');
									info.innerHTML = object.Message + "";
									return;
								}
							}
							else if (object.Code === 200) {
								info.classList.add('success');
								info.innerHTML = object.Message;
							}
							else {
								// console.log(this.responseText);
							}
						}
						else {
							// console.log(this.responseText);
						}
						return;
					}
					// console.log(this.responseText);
					// info.innerHTML = "You sent email successfully!";
				}
				else {
					// console.log(this.responseText);
					// info.classList.add('error');
					// if (this.responseText && JSON.parse(this.responseText)){
					// 	var objectError = JSON.parse(this.responseText);
					// 	if (objectError && objectError.Message){
					// 		info.innerHTML = objectError.Message + "";
					// 		return;
					// 	}
					// }
					// info.innerHTML = "Unable to connect to server!";
				}

			};
			// xhttp.open("GET", "https://jsonplaceholder.typicode.com/posts", true);
			xhttp.open("POST", '/post-email-footer', true);
			xhttp.setRequestHeader("Content-type", "application/json;charset=UTF-8");
			xhttp.send(JSON.stringify(data));
		})
	}
}


// swiper 5 & 6 in fDetailProducts
function fDetailProducts() {
	if (document.querySelector('.details-infor__wrapper')) {
		// effect star
		var starSection = document.querySelectorAll('.detail-infor__content .rate-section');
		starSection.forEach(function (item) {
			var child = item.lastElementChild;

			if (child) {
				child = child.children[0];

				if (child) {
					child.children[0];
					if (child) {
						var getStar = child.getAttribute('data-star');
						var stars = child.children;

						for (var i = 1; i < stars.length; i++) {
							if (i === parseInt(getStar)) {
								for (var j = i; j > 0; j--) {
									child.children[j - 1].classList.add('active');
								}
							}
						}
					}
				}
			}
		})

		//create swiper information product
		var swiper5 = new Swiper(`.details-infor__wrapper .product-feature-warranty .product-selected > .product-selected--1 > .swiper-container`, {
			// spaceBetween: 15,
			slidesPerView: 1,
		});

		var swiper6 = new Swiper(`.details-infor__wrapper .product-feature-warranty .product-selected > .product-selected--1 > .feature-icons > .swiper-container`, {
			spaceBetween: 15,
			slidesPerView: 5,
		});

		// review
		var review = document.querySelectorAll('.details-review__wrapper .details-review__form .items-star .star');
		if (review) {
			review.forEach((element, index) => element.addEventListener('mouseover', () => {
				review.forEach(r => r.classList.remove('active'));
				for (var i = index; i >= 0; i--) {
					review[i].classList.add('active');
				}
			}))

			review.forEach((element, index) => element.addEventListener('click', () => {
				review.forEach(r => { r.classList.remove('active'); r.classList.remove('selected') });
				review[index].classList.add('selected');

				var star = <HTMLInputElement>document.getElementById('reviewStar');
				star.value = "" + (index + 1);
				for (var i = index; i >= 0; i--) {
					review[i].classList.add('active');
				}
			}))
		}

		// comment star
		var commentRates = document.querySelectorAll('.details-review__wrapper .details-user__comment .comment-items .items-star');
		if (commentRates) {
			commentRates.forEach(function (rate) {
				var value = parseInt(rate.getAttribute('data-selected'));
				for (var i = value - 1; i >= 0; i--) {
					rate.children[i].classList.add('active');
				}
			})
		}

		// more comment
		var commentMore = document.querySelector('.details-review__wrapper .details-user__comment .comment__more--btn');
		var commentReviews = document.querySelector('.details-review__wrapper .details-user__comment .comment__reviews');
		if (commentMore && commentReviews) {
			// visible là đang hiện 4 mục
			var visibleComment = commentMore.getAttribute('data-comment-visibled');

			// tổng số review
			var reviewComment = commentReviews.getAttribute('data-comment-reviews');

			if (visibleComment < reviewComment) {
				var cmtActive = document.querySelector('.details-review__wrapper .details-user__comment .comment__more');
				cmtActive.classList.add('active');

				commentMore.addEventListener('click', function () {
					// get api
					var xhttp = new XMLHttpRequest();
					xhttp.onreadystatechange = function () {
						if (this.readyState == 4 && this.status == 200) {
							var blockText = document.createElement('div');
							blockText.innerHTML = this.responseText;
							var iVisible = parseInt(visibleComment);
							// alert(blockText.children.length);
							if (iVisible && blockText.children) {
								visibleComment = (iVisible + blockText.children.length) + "";
								commentMore.setAttribute('data-comment-visibled', visibleComment);

								if (visibleComment >= reviewComment) {
									cmtActive.classList.remove('active');
								}

								var wrapper = document.querySelector('.details-review__wrapper .details-user__comment .comment__section');
								wrapper.innerHTML += blockText.innerHTML;
							}
							else {
								// console.log('Error!!!');
							}
						}
						else {
							// alert(`not found comment ${parseInt(visibleComment) + 1}`);
							return;
						}
					};
					// xhttp.open("GET", "https://jsonplaceholder.typicode.com/posts", true);
					xhttp.open("GET", `/get-comment/${parseInt(visibleComment) + 1}`, true);
					xhttp.send();
				})
			}
		}
	}
}

const updateURLParams = function (paramsToUpdate: any) {
	// Lấy tất cả các tham số truy vấn từ URL hiện tại
	var searchParams = new URLSearchParams(window.location.search);

	// Lặp qua các tham số cần cập nhật
	for (var key in paramsToUpdate) {
		if (paramsToUpdate.hasOwnProperty(key)) {
			searchParams.set(key, paramsToUpdate[key]);
		}
	}

	// Tạo URL mới với các thay đổi
	var newURL = window.location.pathname + '?' + searchParams.toString();

	// Cập nhật URL trên trình duyệt
	window.history.pushState({ paramsToUpdate }, '', newURL);
}

const removeQueryParam = function (paramName: any) {
	// Lấy tất cả các tham số truy vấn từ URL hiện tại
	var searchParams = new URLSearchParams(window.location.search);

	// Loại bỏ tham số truy vấn
	searchParams.delete(paramName);

	// Tạo URL mới với các thay đổi
	var newURL;

	if (searchParams.toString() !== '') {
		newURL = window.location.pathname + '?' + searchParams.toString();
	} else {
		newURL = window.location.pathname;
	}

	// Cập nhật URL trên trình duyệt
	window.history.pushState({ path: newURL }, '', newURL);
}

function toggleinput() {
	if (!document.querySelector('.faq-content__wrapper')) {
		return;
	}
	var contentList = document.querySelectorAll(".content-right__list .faq-right__list");
	if (!contentList) {
		return;
	}
	contentList.forEach(item => item.addEventListener('click', () => {
		// xoa active
		if (item.classList.contains('active')) {
			item.classList.remove('active');

			removeQueryParam('data');
			// const urlDefault = item.getAttribute('data-default');
			// window.history.pushState({ urlDefault }, '', urlDefault);
		}
		else {
			contentList.forEach(function (e) {
				e.classList.remove("active");
			})

			// them active vao item da click
			item.classList.add("active");

			const url = item.getAttribute('data-url');
			updateURLParams({ data: url });
			// window.history.pushState({ url }, '', url);
		}

		// var reScroll = document.getElementById("scrollToContent");
		// reScroll.scrollIntoView();
	}))

	var listCate = document.querySelectorAll('.faq-content__wrapper .faq-content .faq-left__list');
	if (!listCate) {
		return;
	}
	listCate.forEach(function (itemCate) {
		itemCate.addEventListener('click', function () {
			var value = itemCate.getAttribute('data-categories');

			// Get api
			var xhttp = new XMLHttpRequest();
			xhttp.onreadystatechange = function () {
				if (this.readyState == 4 && this.status == 200) {
					var wrapper = document.querySelector('.faq-content__wrapper .faq-content__right .faq-right__items');
					wrapper.innerHTML = this.responseText;

					var contentListAjax = document.querySelectorAll(".content-right__list .faq-right__list");
					if (!contentListAjax) {
						return;
					}
					contentListAjax.forEach(function (item, idx) {
						if (item.classList.contains('active')) {
							window.delHandle(contentListAjax, 'active', idx);
							const url = item.getAttribute('data-url');
							updateURLParams({ data: url });
						}

						item.addEventListener('click', () => {
							// xoa active
							if (item.classList.contains('active')) {
								item.classList.remove('active');

								removeQueryParam('data');

								// const urlDefault = item.getAttribute('data-default');
								// console.log(urlDefault);
								// window.history.pushState({ urlDefault }, '', urlDefault);
							}
							else {
								contentListAjax.forEach(function (e) {
									e.classList.remove("active");
								})

								// them active vao item da click
								item.classList.add("active");

								const url = item.getAttribute('data-url');
								updateURLParams({ data: url });

							}
						})
					})
				}
				else {
					// console.log(`not found ${value}`);
					return;
				}
			};
			// xhttp.open("GET", "https://jsonplaceholder.typicode.com/posts", true);
			xhttp.open("GET", `/get-cate-faq/${value}`, true);
			xhttp.send();

			// active
			if (itemCate.classList.contains('active')) {
				itemCate.classList.remove('active');
			}
			else {
				listCate.forEach(function (e) {
					e.classList.remove("active");
				})

				// them active vao item da click
				itemCate.classList.add("active");
			}
		})
	})

	function handlePopState(event: any) {
		// event.state chứa dữ liệu mà bạn đã đặt trong history.pushState hoặc history.replaceState
		var stateData = event.state;
		console.log(stateData);

		// Kiểm tra và xử lý stateData theo nhu cầu của bạn
		if (stateData) {
			// Ví dụ: log dữ liệu trạng thái
			console.log("Popstate event data:", stateData);
		} else {
			// Trường hợp không có dữ liệu trạng thái
			console.log("No popstate event data");
		}

		var paramValue = window.getQueryParam("data");
		// const idxPop = window.isNonNegativeNumber(paramValue) ? parseInt(paramValue) : null;
		console.log(paramValue);
		var contentListCurrent = document.querySelectorAll(".content-right__list .faq-right__list");
		if (paramValue === null) {
			window.delHandle(contentListCurrent, 'active');
		} else {
			var flag = false;
			contentListCurrent.forEach(function (item) {
				const urlValue = item.getAttribute('data-url');
				if (paramValue === urlValue) {
					window.delHandle(contentListCurrent, 'active');
					item.classList.add('active');
					flag = true;
				}
			})

			if (flag === false) {
				console.error('Param not found in the list');
				window.delHandle(contentListCurrent, 'active');
			}
		}

		// window.delHandle(sels, 'is-showed');

		// const optPop = opts[idxPop];
		// const selPop = <HTMLElement>sels[idxPop];

		// if (optPop && selPop) {
		// 	optPop.classList.add('is-actived');
		// 	selPop.style.display = "";
		// 	selPop.classList.add('is-showed');
		// }
	}

	window.addEventListener('popstate', handlePopState);
}


// var swiper6 = new Swiper(".details-infor__wrapper .product-feature-warranty .product-selected--1 .swiper-container", {
// 	// spaceBetween: 15,
// 	slidesPerView: 1,
// });
// var swiper6 = new Swiper(".details-infor__wrapper .product-feature-warranty .product-selected--2 .swiper-container", {
// 	// spaceBetween: 15,
// 	slidesPerView: 1,

// });
// var swiper5 = new Swiper(".details-infor__wrapper .product-feature-warranty .product-selected--1 .feature-icons .swiper-container", {
// 	spaceBetween: 15,
// 	slidesPerView: 5,
// });
// function fLoadSwiperWarranty(){
// 	alert(1);
// 	// const product_details = document.querySelectorAll(".details-infor__wrapper .product-feature-warranty .nav-feature .product-feature")
// 	// console.log(product_details);
// 	// product_details.forEach(function(e, index){
// 	// 	// var swiper6 = new Swiper(".details-infor__wrapper .product-feature-warranty .product-selected--1 .swiper-container", {
// 	// 	// 	// spaceBetween: 15,
// 	// 	// 	slidesPerView: 1,
// 	// 	// });
// 	// 	console.log(index);
// 	// 	var swiper7 = new Swiper(`.details-infor__wrapper .product-feature-warranty .product-selected > .product-selected--${index + 1} > .feature-icons > .swiper-container`, {
// 	// 		spaceBetween: 15,
// 	// 		slidesPerView: 5,
// 	// 	});
// 	// })
// }



const showContentDetails = () => {
	const list = document.querySelectorAll(".details-infor__wrapper .feature-details .dropdown-feature")

	list.forEach(element => element.addEventListener('click', () => {
		const caret1 = document.querySelectorAll(".details-infor__wrapper .feature-details .dropdown-feature .active-caret")

		const listactive = document.querySelector(".details-infor__wrapper .feature-details .dropdown-feature .active")
		var child = element.children

		for (var i = 0; i < child.length; i++) {
			var caret = child[i].children
			if (caret1 === null) {
				caret[0].classList.add("active-caret")
			}
			else {
				caret1.forEach(element => element.classList.remove("active-caret"))
				caret[0].classList.add("active-caret")
			}

		}

		if (listactive === null) {
			child[1].classList.add("active")
		} else {
			child[1].classList.add("active")
			listactive.classList.remove("active")

		}

	}))
}

const navSelect = () => {
	const mattress3 = document.querySelectorAll(".mattress-recommend__wrapper .options-recommend .options")
	const mattress2 = document.querySelectorAll(".mattress-collections__wrapper .mattress-collections .collections-section .options")
	const product_details1 = document.querySelectorAll(".details-infor__wrapper .product-feature-warranty .nav-feature .product-feature")
	const product_details2 = document.querySelectorAll(".details-function-benefit__wrapper .details-nav .nav-function .product-feature")
	const dealer_digital = document.querySelectorAll(".dealer-navigation-digital__wrapper .dealer-navigation-digital a")
	const review = document.querySelectorAll(".details-review__wrapper .user-comment-section")

	mattress2.forEach(element => element.addEventListener('click', () => {
		const active_item = document.querySelector('.mattress-collections__wrapper .mattress-collections .collections-section .active');
		active_item.classList.remove('active')
		element.classList.add("active")
	}))

	mattress3.forEach(element => element.addEventListener('click', () => {
		const active_item = document.querySelector('.mattress-recommend__wrapper .options-recommend .active');
		active_item.classList.remove('active')
		element.classList.add("active")
	}))

	product_details1.forEach((element, index) => element.addEventListener('click', () => {
		const active_item = document.querySelector('.details-infor__wrapper .product-feature-warranty .nav-feature .active');
		active_item.classList.remove('active')
		element.classList.add("active")

		const activeProduct = document.querySelectorAll('.details-infor__wrapper .product-feature-warranty .product-selected .product-selected--value');
		activeProduct.forEach(product => product.classList.remove('active'));
		activeProduct[index].classList.add('active');

		new Swiper(`.details-infor__wrapper .product-feature-warranty .product-selected > .product-selected--${index + 1} > .swiper-container`, {
			// spaceBetween: 15,
			slidesPerView: 1,
		});

		new Swiper(`.details-infor__wrapper .product-feature-warranty .product-selected > .product-selected--${index + 1} > .feature-icons > .swiper-container`, {
			spaceBetween: 15,
			slidesPerView: 5,
		});
	}))

	product_details2.forEach((element, index) => element.addEventListener('click', () => {
		const active_item = document.querySelector('.details-function-benefit__wrapper .details-nav .nav-function .active');
		active_item.classList.remove('active')
		element.classList.add("active")

		const contents = document.querySelectorAll('.details-function-benefit .content--value');
		contents.forEach(function (content) {
			content.classList.remove('active');
		})
		contents[index].classList.add('active');
	}))

	dealer_digital.forEach(element => element.addEventListener('click', () => {
		const active_item = document.querySelector(".dealer-navigation-digital__wrapper .dealer-navigation-digital .active");
		active_item.classList.remove('active')
		element.classList.add("active")
	}))

	review.forEach(element => element.children[0].addEventListener('click', () => {
		// const active_item = document.querySelector(".details-review__wrapper .uesr-comment");
		// active_item.classList.remove('active')
		if (element.children[1] === null) {

		} else {
			element.children[1].classList.toggle("active")
		}

		// element.classList.add("active")
	}))

}

// const changeActive = (active__item,element) =>{
// 		// const active_item = document.querySelector('.mattress-recommend__wrapper .left-recommend .options-recommend a.active');
// 		active__item.classList.remove('active')
// 		element.classList.add("active")
// }



// const showSubMenuMobile = () => {
// const btn = document.querySelector('.btn-menu');
//   const btn_close = document.querySelector('.mobile-navbar-close');
// const overlayMennu = document.querySelector('.mobile-wapper');
// // SHOW MAIN MENU !!!
// if (btn) {
// 	btn.addEventListener('click', (e) => {
// 		document.querySelector('body').classList.toggle('disabled');
// 		btn.classList.add('show');
// 		// mainoverlay.classList.add('side-nav');
//           overlayMennu.classList.add('show-nav');

// 		// CLOSE ALL SUB MENU

// 	});
// }if(btn_close){
// 	btn_close.addEventListener('click', (e) => {
// 		document.querySelector('body').classList.remove('disabled');
// 		btn.classList.remove('show');
// 		// mainoverlay.classList.remove('show__overlay');
//           overlayMennu.classList.remove('show-nav');

// 		// CLOSE ALL SUB MENU

// 	});
//   }else {
// 	console.log(`Không tồn tại element :=> .navBarHamburger__wrapper`);
// }
// }

var endCheck = function () {
	var checkBox = document.querySelectorAll(".mobile-nav-sub__link");
	checkBox.forEach(box => {
		box.addEventListener('click', function () {
			Uncheck();
		})
	});
}

function Uncheck() {
	var checkedBox = <HTMLInputElement>document.getElementById("mobile__checkbox");
	checkedBox.checked = false;
}

// About us story
var swiperPartner = new Swiper(".about-story-partner__wrapper .about-story-partner__list", {
	slidesPerView: 5,
	spaceBetween: 150,
	slidesPerGroup: 1,
	loop: true,


	navigation: {
		nextEl: ".about-story-partner__wrapper .swiper-button-next",
		prevEl: ".about-story-partner__wrapper .swiper-button-prev",
	},
	breakpoints: {
		120: {
			slidesPerView: 1,
			spaceBetween: 50,
		},
		320: {
			slidesPerView: 1,
			spaceBetween: 50,
		},
		425: {
			slidesPerView: 2,
			spaceBetween: 50,
		},
		600: {
			slidesPerView: 3,
			spaceBetween: 50,
		},
		768: {
			slidesPerView: 4,
			spaceBetween: 50,
		},
		1024: {
			slidesPerView: 5,
			spaceBetween: 70,
		},
		1440: {
			slidesPerView: 5,
			spaceBetween: 120,
		}
	}
});

// About us media
// var swiperReleases = new Swiper(".about-media-releases .releases-pagination .releases-pagination-swiper", {
// 	slidesPerView: 5,
// 	spaceBetween: 30,
// 	slidesPerGroup: 1,

// 	navigation: {
// 		nextEl: ".about-media-releases .releases-pagination .swiper-button-next",
// 		prevEl: ".about-media-releases .releases-pagination .swiper-button-prev",
// 	}
// });





var clickPaginationReleases = function () {
	if (document.querySelector('.about-media-releases__wrapper')) {
		var reBox = document.querySelectorAll(".about-media-releases__content .releases-box .releases-box-item");
		var pageNumber = 4;
		var page = Math.ceil(reBox.length / pageNumber);
		var iNumber = 1;

		for (var i = 0; i < reBox.length; i++) {
			if (Math.floor(i / pageNumber + 1) == iNumber) {
				reBox[i].classList.remove("hidden");
			}
			else {
				reBox[i].classList.add("hidden");
			}
		}

		if (page > 1) {
			var addPagination = document.querySelector('.about-media-releases__content');
			if (addPagination === null) {
				return;
			}

			var rePagination = document.createElement('div');
			rePagination.classList.add('releases-pagination');

			var reSwiper = document.createElement('div');
			reSwiper.classList.add('releases-pagination-swiper');
			reSwiper.classList.add('swiper-container');

			var reWrapper = document.createElement('div');
			reWrapper.classList.add('swiper-wrapper');

			for (var i = 0; i < page; i++) {
				reWrapper.innerHTML += `<div class="releases-pagination__button swiper-slide" data-releases="${i + 1}"><span>${i + 1}<span></div>`;
			}

			var reNext = document.createElement('div');
			var rePrev = document.createElement('div');
			reNext.classList.add('swiper-button-next');
			rePrev.classList.add('swiper-button-prev');
			reNext.classList.add('releases-pagination__next');
			rePrev.classList.add('releases-pagination__prev');


			reSwiper.appendChild(reWrapper);
			reSwiper.appendChild(reNext);
			reSwiper.appendChild(rePrev);

			rePagination.appendChild(reSwiper);

			addPagination.appendChild(rePagination);
		}

		var swiperReleases = new Swiper(".about-media-releases .releases-pagination .releases-pagination-swiper", {
			slidesPerView: 4,
			spaceBetween: 30,
			slidesPerGroup: 1,
			breakpoints: {
				320: {
					spaceBetween: 25,
				},
				640: {
					spaceBetween: 30,
				}
			},
			navigation: {
				nextEl: ".about-media-releases .releases-pagination .swiper-button-next",
				prevEl: ".about-media-releases .releases-pagination .swiper-button-prev",
			}
		});

		document.querySelector(".about-media-releases .releases-pagination .releases-pagination-swiper .releases-pagination__button").classList.add("active");
		var pagination = document.querySelectorAll(".about-media-releases .releases-pagination .releases-pagination__button");
		pagination.forEach(function (p) {
			p.addEventListener("click", function (e) {
				pagination.forEach(function (item) {
					if (item.classList.contains("active")) {
						item.classList.remove("active");
					}
				})
				p.classList.add('active');

				var number = p.getAttribute("data-releases");
				var iNumber = parseInt(number);
				var reBox = document.querySelectorAll(".about-media-releases__content .releases-box .releases-box-item");

				var pageNumber = 4;

				for (var i = 0; i < reBox.length; i++) {
					if (Math.floor(i / pageNumber + 1) == iNumber) {
						reBox[i].classList.remove("hidden");
					}
					else {
						reBox[i].classList.add("hidden");
					}
				}
				// var reScroll = document.getElementById("scrollToReleases");
				// reScroll.scrollIntoView();
			})
		})
	}
}

var clickFancybox = function () {
	if (document.querySelector('.about-media-video__wrapper')) {
		// about media video
		var swiperVideo = new Swiper(".about-media-video__wrapper .releases-pagination .releases-pagination-swiper", {
			slidesPerView: 4,
			spaceBetween: 30,
			slidesPerGroup: 1,
			breakpoints: {
				320: {
					spaceBetween: 25,
				},
				640: {
					spaceBetween: 30,
				}
			},
			navigation: {
				nextEl: ".about-media-video__wrapper .releases-pagination .swiper-button-next",
				prevEl: ".about-media-video__wrapper .releases-pagination .swiper-button-prev",
			}
		});

		document.querySelector(".about-media-video__wrapper .releases-pagination .releases-pagination-swiper .releases-pagination__button").classList.add("active");
		var pagination = document.querySelectorAll(".about-media-video__wrapper .releases-pagination .releases-pagination__button");
		pagination.forEach(function (p) {
			p.addEventListener("click", function (e) {

				// active number page
				pagination.forEach(function (item) {
					if (item.classList.contains("active")) {
						item.classList.remove("active");
					}
				})
				p.classList.add('active');

				// get data-page
				var value = p.getAttribute("data-url");

				// Get api
				var xhttp = new XMLHttpRequest();
				xhttp.onreadystatechange = function () {
					if (this.readyState == 4 && this.status == 200) {
						var wrapper = document.querySelector('.about-media-video__wrapper .about-media-video__list');
						wrapper.innerHTML = this.responseText;
					}
					else {
						// console.log(`not found ${value}`);
						return;
					}
				};
				// xhttp.open("GET", "https://jsonplaceholder.typicode.com/posts", true);
				xhttp.open("GET", `/get-about-gallery/${value}`, true);
				xhttp.send();

				// scroll
				// var reScroll = document.getElementById("scrollToVideo");
				// reScroll.scrollIntoView();
			})
		})
	}
}

var swiperModel = new Swiper(".about-media-video__wrapper .model .video__model-swiper", {
	slidesPerView: 1,
	spaceBetween: 50,
	slidesPerGroup: 1,

	navigation: {
		nextEl: ".about-media-video__wrapper .model .swiper-button-next",
		prevEl: ".about-media-video__wrapper .model .swiper-button-prev",
	}
});

function popupHandle() {
	if (document.querySelector('.about-media-video__wrapper')) {
		// event click model
		var popupModelVideo = document.querySelectorAll(".about-media-video__wrapper .about-media-video__item");
		popupModelVideo.forEach(function (pop) {
			// pop.childNodes

			pop.addEventListener('click', function () {
				var model = document.getElementById('model-video');
				if (model) {
					if (!model.classList.contains('active')) {
						model.classList.add('active');
						new Swiper(".about-media-video__wrapper .model .video__model-swiper", {
							slidesPerView: 1,
							spaceBetween: 50,
							slidesPerGroup: 1,

							navigation: {
								nextEl: ".about-media-video__wrapper .model .swiper-button-next",
								prevEl: ".about-media-video__wrapper .model .swiper-button-prev",
							}
						});
					}
				}

				var dataModel = pop.getAttribute('data-model');
				// Get api
				var xhttp = new XMLHttpRequest();
				xhttp.onreadystatechange = function () {
					if (this.readyState == 4 && this.status == 200) {
						var modelList = document.querySelector('.about-media-video__wrapper .about-media-video .model .swiper-wrapper');
						modelList.innerHTML = this.responseText;

						new Swiper(".about-media-video__wrapper .model .video__model-swiper", {
							slidesPerView: 1,
							spaceBetween: 50,
							slidesPerGroup: 1,

							navigation: {
								nextEl: ".about-media-video__wrapper .model .swiper-button-next",
								prevEl: ".about-media-video__wrapper .model .swiper-button-prev",
							}
						});
					}
					else {
						// console.log(`not found model ${dataModel}`);
						return;
					}
				};
				// xhttp.open("GET", "https://jsonplaceholder.typicode.com/posts", true);
				xhttp.open("GET", `/get-about-gallery-popup/${dataModel}`, true);
				xhttp.send();

				// .video__model-item.swiper-slide
				//             img.video__model-item--img(src="./assets/images/about/default-partner.png" alt="partner")
				//             h5.video__model-item--title Lorem ipsum dolor sit, amet consectetur adipisicing elit. Animi, quam.
			})
		})
		// event closed in model
		var closedPopupModelVideo = document.querySelector('.model-closed');
		closedPopupModelVideo.addEventListener('click', function () {
			var model = document.getElementById('model-video');
			if (model) {
				if (model.classList.contains('active')) {
					model.classList.remove('active');
				}
			}
		})
	}
}

var swiperAboutBlog = function () {
	if (!document.querySelector('.about-story-blog__wrapper')) {
		return;
	}
	// about blog box
	var swiperVideo = new Swiper(".about-story-blog__wrapper .about-story-blog .releases-pagination-swiper", {
		slidesPerView: 4,
		spaceBetween: 30,
		slidesPerGroup: 1,
		breakpoints: {
			320: {
				spaceBetween: 25,
			},
			640: {
				spaceBetween: 30,
			}
		},
		navigation: {
			nextEl: ".about-story-blog__wrapper .about-story-blog .swiper-button-next",
			prevEl: ".about-story-blog__wrapper .about-story-blog .swiper-button-prev",
		}
	});

	document.querySelector(".about-story-blog__wrapper .releases-pagination .releases-pagination-swiper .releases-pagination__button").classList.add("active");
	var pagination = document.querySelectorAll(".about-story-blog__wrapper .releases-pagination .releases-pagination__button");
	pagination.forEach(function (p) {
		p.addEventListener("click", function (e) {

			// active number page
			pagination.forEach(function (item) {
				if (item.classList.contains("active")) {
					item.classList.remove("active");
				}
			})
			p.classList.add('active');

			// get data-page
			var value = p.getAttribute("data-page");

			// Get api
			var xhttp = new XMLHttpRequest();
			xhttp.onreadystatechange = function () {
				if (this.readyState == 4 && this.status == 200) {
					var wrapper = document.querySelector('.about-story-blog .about-story-blog--left .blog');
					wrapper.innerHTML = this.responseText;
				}
				else {
					// console.log(`Not Found ${value}`);
					return false;
				}
			};
			// xhttp.open("GET", "https://jsonplaceholder.typicode.com/posts", true);
			xhttp.open("GET", `/get-about-blog/${value}`, true);
			xhttp.send();

			// scroll
			// var reScroll = document.getElementById("scrollToBlog");
			// reScroll.scrollIntoView();
		})
	})
}

function validateEmail(email: string) {
	const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}

var submitDetailBlog = function () {
	if (document.querySelector('.blog-detail__wrapper')) {

		var formShare = document.getElementById('share-detail-form');
		var emailInput = (<HTMLInputElement>document.getElementById('emailShare'));
		var titlePost = document.querySelector('.blog-detail--left__title');
		var info = document.getElementById('shareFormInfo');
		emailInput.addEventListener('change', function (e) {
			info.innerHTML = "";
			if (info.classList.contains('success')) {
				info.classList.remove('success');
			}
			else if (info.classList.contains('error')) {
				info.classList.remove('error');
			}
		})

		formShare.addEventListener('submit', function (e) {
			e.preventDefault();
			var email = emailInput.value;

			if (validateEmail(email)) {
				// Get api
				var title = titlePost.innerHTML;
				var dataSubmit = {
					email: email,
					titlePost: title
				}

				var xhttp = new XMLHttpRequest();
				xhttp.onreadystatechange = function () {
					if (this.readyState == 4 && this.status == 200) {
						if (this.responseText) {
							var object = JSON.parse(this.responseText);
							if (object && object.Code) {
								if (object.Code === 400) {
									if (object && object.Message) {
										info.classList.add('error');
										info.innerHTML = object.Message + "";
									}
								}
								else if (object.Code === 200) {
									info.classList.add('success');
									info.innerHTML = object.Message + "";
								}
								else {
									// console.log(this.responseText);
								}
								return;
							}
						}
						// console.log(this.responseText);
						return;
					}
					else {
						// console.log(this.responseText);
						// info.classList.add('error');
						// if (this.responseText && JSON.parse(this.responseText)){
						// 	var objectError = JSON.parse(this.responseText);
						// 	if (objectError && objectError.Message){
						// 		info.innerHTML = objectError.Message + "";
						// 		return;
						// 	}
						// 	console.log(this.responseText);
						// 	return;
						// }
					}
				};
				// xhttp.open("GET", "https://jsonplaceholder.typicode.com/posts", true);
				xhttp.open("POST", `/post-detail-blog`, true);
				xhttp.setRequestHeader("Content-type", "application/json;charset=UTF-8");
				xhttp.send(JSON.stringify(dataSubmit));
			}
			else {
				var data = emailInput.getAttribute('data-info');
				info.classList.add('error');
				info.innerHTML = data;
			}
		})
	}
}

var swiperDealerProduct = function () {
	if (document.querySelector('.dealer-product__wrapper')) {
		// about blog box
		var swiperDL = new Swiper(".dealer-product__wrapper .dealer-product .releases-pagination-swiper", {
			slidesPerView: 4,
			spaceBetween: 30,
			slidesPerGroup: 1,

			navigation: {
				nextEl: ".dealer-product__wrapper .dealer-product .swiper-button-next",
				prevEl: ".dealer-product__wrapper .dealer-product .swiper-button-prev",
			}
		});
		var pagination = document.querySelectorAll('.dealer-product__wrapper .dealer-product .releases-pagination__button');
		pagination.forEach(function (item) {
			item.addEventListener('click', function () {
				// active number page
				pagination.forEach(function (e) {
					e.classList.remove('active');
				})
				item.classList.add('active');

				var value = item.getAttribute('data-url');

				// Get api
				var xhttp = new XMLHttpRequest();
				xhttp.onreadystatechange = function () {
					if (this.readyState == 4 && this.status == 200) {
						var wrapper = document.querySelector('.dealer-product__wrapper .dealer-product .dealer-product-list');
						wrapper.innerHTML = this.responseText;
					}
					else {
						// console.log(`not found dealer product ${value}`);
						return false;
					}
				};
				// xhttp.open("GET", "https://jsonplaceholder.typicode.com/posts", true);
				xhttp.open("GET", `/get-dealer-product/${value}`, true);
				xhttp.send();
			})
		})


		// scroll
		var reScroll = document.getElementById("scrollToVideo");
		reScroll.scrollIntoView();
	}
}

function fMattressCollection() {
	if (document.querySelector('.mattress-collections__wrapper')) {
		var options = document.querySelectorAll('.collections-section .options');
		options.forEach(function (option) {
			option.addEventListener('click', function (e) {
				e.preventDefault();
				if (!option.classList.contains('active')) {
					options.forEach(function (o) {
						o.classList.remove('active');
					})
					option.classList.add('active');
				}
				// Get api
				var value = option.getAttribute('data-option');
				var xhttp = new XMLHttpRequest();
				xhttp.onreadystatechange = function () {
					if (this.readyState == 4 && this.status == 200) {
						var wrapper = document.querySelector('.mattress-collections__wrapper .mattress-collections .collections__products');
						wrapper.innerHTML = this.responseText;
						// return true;
					}
					else {
						// console.log(`not found explore collection ${value}`);
						return false;
					}
				};
				// xhttp.open("GET", "https://jsonplaceholder.typicode.com/posts", true);
				xhttp.open("GET", `/get-explore-collection/${value}`, true);
				xhttp.send();

				// Get api
				var xhttp2 = new XMLHttpRequest();
				xhttp2.onreadystatechange = function () {
					if (this.readyState == 4 && this.status == 200) {
						var wrapper = document.querySelector('.mattress-recommend__wrapper > .container');
						wrapper.innerHTML = this.responseText;

						var btnArrows = document.querySelectorAll('.btn .btn-arrow');
						btnArrows.forEach(function (btn) {
							btn.innerHTML += ' ' + `<svg xmlns="http://www.w3.org/2000/svg" width="12.922" height="12.791" viewBox="0 0 12.922 12.791"><path id="Path_126" data-name="Path 126" d="M0,0,5.188,5.33S10.377-.136,10.377,0" transform="translate(6.592 11.377) rotate(-90)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/><path id="Path_127" data-name="Path 127" d="M0,0,5.188,5.33S10.377-.136,10.377,0" transform="translate(1.411 11.377) rotate(-90)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg>`;
						});

						var recommends = document.querySelectorAll('.options-recommend .options-recommend__link');
						recommends.forEach(function (recommend) {
							recommend.addEventListener('click', function (e) {
								e.preventDefault();
								if (!recommend.classList.contains('active')) {
									recommends.forEach(function (item) {
										item.classList.remove('active');
									})
									recommend.classList.add('active');

									// Get api
									var value = recommend.getAttribute('data-recommend');
									var xhttp = new XMLHttpRequest();
									xhttp.onreadystatechange = function () {
										if (this.readyState == 4 && this.status == 200) {
											var wrapper = document.querySelector('.mattress-recommend__wrapper .mattress-recommend');
											wrapper.innerHTML = this.responseText;

											var btnArrows = document.querySelectorAll('.btn .btn-arrow');
											btnArrows.forEach(function (btn) {
												btn.innerHTML += ' ' + `<svg xmlns="http://www.w3.org/2000/svg" width="12.922" height="12.791" viewBox="0 0 12.922 12.791"><path id="Path_126" data-name="Path 126" d="M0,0,5.188,5.33S10.377-.136,10.377,0" transform="translate(6.592 11.377) rotate(-90)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/><path id="Path_127" data-name="Path 127" d="M0,0,5.188,5.33S10.377-.136,10.377,0" transform="translate(1.411 11.377) rotate(-90)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg>`;
											});

											new Swiper('.mattress-recommend__wrapper .right-recommend .swiper-container', {
												slidesPerView: 1,

												loop: true,
												navigation: {
													nextEl: ".swiper-button-next",
													prevEl: ".swiper-button-prev",
												},
											});
											// return true;
										}
										else {
											// console.log(`not found recommend collection ${value}`);
											return false;
										}
									};
									// xhttp.open("GET", "https://jsonplaceholder.typicode.com/posts", true);
									xhttp.open("GET", `/get-recommend-collection/${value}`, true);
									xhttp.send();
								}
							})
						})

					}
					else {
						// console.log(`not found explore collection2 - ${value}`);
						return false;
					}
				};
				// xhttp.open("GET", "https://jsonplaceholder.typicode.com/posts", true);
				xhttp2.open("GET", `/get-explore-collection-2/${value}`, true);
				xhttp2.send();
			})
		})
	}

	if (document.querySelector('.mattress-recommend__wrapper')) {
		var recommends = document.querySelectorAll('.options-recommend .options-recommend__link');
		recommends.forEach(function (recommend) {
			recommend.addEventListener('click', function (e) {
				e.preventDefault();
				if (!recommend.classList.contains('active')) {
					recommends.forEach(function (item) {
						item.classList.remove('active');
					})
					recommend.classList.add('active');

					// Get api
					var value = recommend.getAttribute('data-recommend');
					var xhttp = new XMLHttpRequest();
					xhttp.onreadystatechange = function () {
						if (this.readyState == 4 && this.status == 200) {
							var wrapper = document.querySelector('.mattress-recommend__wrapper .mattress-recommend');
							wrapper.innerHTML = this.responseText;

							var btnArrows = document.querySelectorAll('.btn .btn-arrow');
							btnArrows.forEach(function (btn) {
								btn.innerHTML += ' ' + `<svg xmlns="http://www.w3.org/2000/svg" width="12.922" height="12.791" viewBox="0 0 12.922 12.791"><path id="Path_126" data-name="Path 126" d="M0,0,5.188,5.33S10.377-.136,10.377,0" transform="translate(6.592 11.377) rotate(-90)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/><path id="Path_127" data-name="Path 127" d="M0,0,5.188,5.33S10.377-.136,10.377,0" transform="translate(1.411 11.377) rotate(-90)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg>`;
							});

							new Swiper('.mattress-recommend__wrapper .right-recommend .swiper-container', {
								slidesPerView: 1,

								loop: true,
								navigation: {
									nextEl: ".swiper-button-next",
									prevEl: ".swiper-button-prev",
								},
							});
						}
						else {
							// console.log(`not found recommend collection ${value}`);
							return;
						}
					};
					// xhttp.open("GET", "https://jsonplaceholder.typicode.com/posts", true);
					xhttp.open("GET", `/get-recommend-collection/${value}`, true);
					xhttp.send();
				}
			})
		})
	}
}

function preventDefalutLink() {
	var link = document.querySelector('.advertise-pic > a');
	if (link) {
		link.addEventListener('click', function (e) {
			e.preventDefault();
		})
	}
}
var scrolltosilkfoam = function () {
	if (document.querySelector('.index-2')) {
		var items = document.querySelector('.slogan .scroll ');
		if (items) {
			// items.forEach(function(item){
			items.addEventListener('click', function (e) {
				e.preventDefault();
				var hrefItem = items.getAttribute('href');
				// console.log(hrefItem);
				var featuredItem = <HTMLElement>document.querySelector(hrefItem);
				var hHeader = document.querySelector('.header_wapper');
				// console.log(hHeader.clientHeight);
				var topFeatured = 0;
				if (featuredItem) {
					var parentFeatured = <HTMLElement>featuredItem.offsetParent;
					var count = 0;
					while (parentFeatured.nodeName !== "BODY" && count < 100) {
						topFeatured += parentFeatured.offsetTop;
						parentFeatured = <HTMLElement>parentFeatured.offsetParent;
						count++;
					}
					topFeatured += featuredItem.offsetTop;
				}

				// Trừ đi thanh Header
				topFeatured -= hHeader.clientHeight;

				if (featuredItem.clientHeight < (window.innerHeight - hHeader.clientHeight)) {
					// Top: 50%, translateY(-50%)
					topFeatured -= (window.innerHeight / 2);
					topFeatured += (featuredItem.clientHeight / 1.5);
					// console.log(topFeatured);
				}

				window.scroll({
					top: topFeatured,
					left: 0,
					behavior: 'smooth',
				});
			})
			// })
		}
	}
}

function scrollMattress() {
	if (document.querySelector('.mattress-type')) {
		var items = document.querySelectorAll('.title-mattress-type .menu__item');
		if (items) {
			items.forEach(function (item) {
				item.addEventListener('click', function (e) {
					e.preventDefault();
					var hrefItem = item.getAttribute('href');
					var featuredItem = <HTMLElement>document.querySelector(hrefItem);
					var hHeader = document.querySelector('.header_wapper');
					// console.log(hrefItem);
					var topFeatured = 0;
					if (featuredItem) {
						var parentFeatured = <HTMLElement>featuredItem.offsetParent;
						var count = 0;
						while (parentFeatured.nodeName !== "BODY" && count < 100) {
							topFeatured += parentFeatured.offsetTop;
							parentFeatured = <HTMLElement>parentFeatured.offsetParent;
							count++;
						}
						topFeatured += featuredItem.offsetTop;
					}

					// Trừ đi thanh Header
					topFeatured -= hHeader.clientHeight;

					if (featuredItem.clientHeight < (window.innerHeight - hHeader.clientHeight)) {
						// Top: 50%, translateY(-50%)
						topFeatured -= (window.innerHeight / 2);
						topFeatured += (featuredItem.clientHeight / 2);
					}

					window.scroll({
						top: topFeatured,
						left: 0,
						behavior: 'smooth',
					});
				})
			})
		}
	}
}

// import { AOS } from 'aos';

function handleGlobal() {
	var global = <HTMLElement>document.querySelector('.about-story-global__img');
	if (global) {
		var parentGlobal = <HTMLElement>global.offsetParent;
		var count = 0;
		var pos = global.offsetTop;
		while (parentGlobal.nodeName !== "BODY" && count < 100) {
			pos += parentGlobal.offsetTop;
			parentGlobal = <HTMLElement>parentGlobal.offsetParent;
			count++;
		}
		var hHeader = document.querySelector('.header_wapper');

		var posHeightGlobal = pos;
		pos -= hHeader.clientHeight;

		if (global.clientHeight < (window.innerHeight - hHeader.clientHeight)) {
			posHeightGlobal -= (window.innerHeight - hHeader.clientHeight);
			posHeightGlobal += (global.clientHeight);

			// Top: 50%, translateY(-50%)
			pos -= (window.innerHeight / 2);
			pos += (global.clientHeight / 2);
		}
		var listIcon = document.querySelectorAll('.about-story-global__img--icon');

		window.addEventListener('scroll', function (e: any) {
			// console.log(window.scrollY + ' - ' + pos);
			if (window.scrollY >= pos) {
				listIcon.forEach(function (icon) {
					icon.classList.add('animate__animated');
					icon.classList.add('animate__bounce');
				})
			}
			else if (window.scrollY < posHeightGlobal) {
				listIcon.forEach(function (icon) {
					icon.classList.remove('animate__animated');
					icon.classList.remove('animate__bounce');
				})
			}
		})

		// window.scroll({
		// 	top: posHeightGlobal,
		// 	left: 0,
		// 	behavior: 'smooth'
		// });
		// console.log(global);
		// .animate__animated.animate__bounce
	}
}

function scrollTopHeader(queryString: any, stringHeader: any = ".header_wapper") {
	var wrapper = <HTMLElement>document.querySelector(queryString);
	var parentWrapper = <HTMLElement>wrapper.offsetParent;
	var pos = wrapper.offsetTop;
	var count = 0;
	while (parentWrapper.nodeName !== "BODY" && count < 100) {
		pos += parentWrapper.offsetTop;
		parentWrapper = <HTMLElement>parentWrapper.offsetParent;
		count++;
	}
	var hHeader = document.querySelector(stringHeader);
	pos -= hHeader.clientHeight;
	window.scroll({
		top: pos,
		left: 0,
		behavior: 'smooth',
	});
	return pos;
}

function handleOrderProduct() {
	if (document.querySelector('.details-infor__wrapper')) {
		var order = document.querySelector('.product-order > a');
		if (!order) {
			return;
		}
		var size = <HTMLSelectElement>document.querySelector('.details-infor__wrapper .detail-infor__content .product-oftions .size');
		var amount = <HTMLInputElement>document.querySelector('.details-infor__wrapper .detail-infor__content .product-oftions .amount');
		if (size.value !== "") {
			if (size.classList.contains('error')) {
				size.classList.remove('error');
			}
			size.classList.add('success');
		}
		else {
			size.classList.remove('error');
			size.classList.remove('success');
		}

		if (amount.value !== "") {
			if (amount.classList.contains('error')) {
				amount.classList.remove('error');
			}
			amount.classList.add('success');
		}
		else {
			amount.classList.remove('error');
			amount.classList.remove('success');
		}

		size.addEventListener('change', function () {
			if (this.value !== "") {
				if (this.classList.contains('error')) {
					this.classList.remove('error');
				}
				this.classList.add('success');
			}
			else {
				this.classList.remove('error');
				this.classList.remove('success');
			}
		})
		amount.addEventListener('change', function () {
			if (this.value !== "") {
				if (this.classList.contains('error')) {
					this.classList.remove('error');
				}
				this.classList.add('success');
			}
			else {
				this.classList.remove('error');
				this.classList.remove('success');
			}
		})

		order.addEventListener('click', function (e) {
			e.preventDefault();
			// Get api
			var titleProduct = document.querySelector('.details-infor__wrapper .detail-infor__content .product-infor__title');
			var idProduct = titleProduct.getAttribute('data-product');


			var check = false;
			if (size.value === "") {
				if (size.classList.contains('success')) {
					size.classList.remove('success');
				}
				size.classList.add('error');
				check = true;
			}

			if (amount.value === "") {
				if (amount.classList.contains('success')) {
					amount.classList.remove('success');
				}
				amount.classList.add('error');
				check = true;
			}

			if (check) {
				// scroll
				scrollTopHeader('.details-infor');
				return false;
			}

			var idSize = size.options[size.selectedIndex].getAttribute('data-property');
			var idQuanlity = amount.value;

			// console.log([size]);
			// console.log(size.options);
			var data = {
				pId: parseInt(idProduct),
				propertyId: parseInt(idSize),
				quantity: parseInt(idQuanlity)
			};
			// console.log(JSON.stringify(data));
			// Get api
			var xhttp = new XMLHttpRequest();
			xhttp.onreadystatechange = function () {
				if (this.readyState == 4 && this.status == 200) {
					// shop cart
					reloadForm();

					removeActiveForm();
					addActiveForm('form-custom__wrapper');
				}
				else {
					// console.log("API order product failed!!!");
					return;
				}
			};
			// xhttp.open("GET", "https://jsonplaceholder.typicode.com/posts", true);
			xhttp.open("POST", '/post-order-product', true);
			xhttp.setRequestHeader("Content-type", "application/json;charset=UTF-8");
			xhttp.send(JSON.stringify(data));
		})
	}
}

// function handleOrderProduct(){
// 	if(document.querySelector('.details-infor__wrapper')){
// 		var titleProduct = document.querySelector('.details-infor__wrapper .detail-infor__content .product-infor__title');
// 		var idProduct = titleProduct.getAttribute('data-product');

// 		var size = <HTMLInputElement> document.querySelector('.details-infor__wrapper .detail-infor__content .product-oftions .size');
// 		var amount = <HTMLInputElement> document.querySelector('.details-infor__wrapper .detail-infor__content .product-oftions .amount');

// 		size.addEventListener('change', function(){
// 			if(this.value === ""){
// 				this.classList.add('error');
// 			}
// 			else{
// 				this.classList.add('success');


// 			}
// 		})
// 		amount.addEventListener('change', function(){
// 			if(this.value === ""){
// 				this.classList.add('error');
// 			}
// 			else{
// 				this.classList.add('success');
// 			}
// 		})
// 	}
// }

// function calcPriceProduct(){
// 	var size = <HTMLInputElement> document.querySelector('.details-infor__wrapper .detail-infor__content .product-oftions .size');
// 	var amount = <HTMLInputElement> document.querySelector('.details-infor__wrapper .detail-infor__content .product-oftions .amount');

// 	var price = document.querySelector('.details-infor__wrapper .detail-infor__content .product-infor__price');

// 	if (!parseInt(size.value) || !parseInt(amount.value)){
// 		return;
// 	}
// 	var iSize = parseInt(size.value);
// 	var iAmount = parseInt(amount.value);

// 	var formatMoney = new Intl.NumberFormat('en-US',
// 	{
// 		style: 'currency',
// 		currency: 'USD'
// 	});

// 	// ("#size").on("change", function (e) {
// 	// 	$('#product-price').text(formatMoney.format(this.value).replace(/\.0+$/, ''));
// 	// });
// }

function heightProductDetails() {
	var items = document.querySelectorAll('.details-comprarison__wrapper .comparision-section>*');
	if (items) {
		var len = 0;
		var lenList = items.length;
		items.forEach(function (item) {
			var num = item.children.length;
			if (len < num) {
				len = num;
				// console.log(len)
			}
		});
		for (let i = 0; i < len; ++i) {
			var max = 0;
			for (let runList = 0; runList < lenList; ++runList) {
				var hChild = items[runList].children[i].clientHeight;
				if (max < hChild) {
					max = hChild;
				}
			}
			for (let runList = 0; runList < lenList; ++runList) {
				var item = <HTMLElement>items[runList].children[i];
				item.style.height = `${max}px`;
			}
		}
	}
}

function popupHandleClick() {
	var popups = document.querySelectorAll('.form-info__popup');
	popups.forEach(function (itemPop) {
		var btnPop = itemPop.querySelector('.btn--link');
		var closePop = itemPop.querySelector('.btn-close');

		btnPop.addEventListener('click', function () {
			itemPop.classList.remove('active');
		})
		closePop.addEventListener('click', function () {
			itemPop.classList.remove('active');
		})

	})
}
function checkBoxPoup() {
	var formDealer = document.querySelector('#form-dealer__wrapper .form-content--group');
	if (formDealer) {
		var formGroupDealer = formDealer.querySelectorAll('.input-group');
		formGroupDealer.forEach(function (itemGroup) {
			var checkBoxes = itemGroup.querySelectorAll('.input-group--checkbox  input[type="checkbox"]');
			if (checkBoxes.length > 0) {
				checkedHandle(checkBoxes);
			}
		})

	}
}
function checkedHandle(checkBoxes: any) {
	// tạo sự kiện cho từng thằng checkbox
	checkBoxes.forEach(function (itemEvent: any) {

		itemEvent.onclick = function (e: any) {
			if (itemEvent.checked === true) {
				checkBoxes.forEach(function (itemBox: any) {
					if (itemBox !== itemEvent) {
						// console.log(itemBox)
						var itemTemp = <HTMLInputElement>itemBox;
						itemTemp.checked = false;
					}
					else {
						itemEvent.checked = true;
					}
				})
			}
			else {
				itemEvent.checked = true;
			}
		}

		// console.log(itemChecked.checked);
	});
}


// Havas scripts


function handleQtyProduct() {
	const inpNumberQty = <HTMLInputElement>document.querySelector('.product-infor-item__qty #numberQty');
	const btnDecrease = document.querySelector('.product-infor-item__qty .decrease_');
	const btnIncrease = document.querySelector('.product-infor-item__qty .increase_');

	if (!inpNumberQty || !btnDecrease || !btnIncrease) {
		return;
	}

	btnDecrease.addEventListener('click', function () {
		decreaseValue(this);
	})
	btnIncrease.addEventListener('click', function () {
		increaseValue(this);
	})

	function increaseValue(_this: any) {
		var value = parseInt(inpNumberQty.value, 10);
		value = isNaN(value) ? 1 : value;
		value++;
		inpNumberQty.value = `${value}`;
	}

	function decreaseValue(_this: any) {
		var value = parseInt(inpNumberQty.value, 10);
		value = isNaN(value) ? 1 : value;
		value < 2 ? value = 2 : '';
		value--;
		inpNumberQty.value = `${value}`;
	}
}

function ImgUpload() {
	let imgWrap: HTMLElement | null = null;
	var imgArray: any = [];

	const upload = document.querySelector('.upload__inputfile');

	// var wrapUpload = document.querySelector('.upload .upload-wrap');
	upload && upload.addEventListener('change', function (e) {
		var maxLength = upload.getAttribute('data-max_length');
		const files = (e.target as HTMLInputElement).files;
		if (!imgWrap) {
			imgWrap = this.closest('.upload')?.querySelector('.upload__img-wrap');
		}
		const filesArr = Array.prototype.slice.call(files);
		imgWrap.innerHTML = "";

		let iterator = 0;
		filesArr.forEach(function (f: File) {
			if (!f.type.match('image.*')) {
				return;
			}

			if (imgArray.length > Number(maxLength)) {
				return false;
			} else {
				const len = imgArray.filter((item: any) => item !== undefined).length;

				if (len > Number(maxLength)) {
					return false;
				} else {
					imgArray.push(f);

					const reader = new FileReader();
					reader.onload = function (e) {
						// const html = `<div class='upload__img-box'><div style='background-image: url(${e.target?.result})' data-number='${document.querySelectorAll(".upload__img-close").length}' data-file='${f.name}' class='img-bg'><div class='upload__img-close'></div></div></div>`;
						const html = `<div class='upload__img-box'><div style='background-image: url(${e.target?.result})' data-number='${document.querySelectorAll(".upload__img-close").length}' data-file='${f.name}' class='img-bg'></div></div>`;
						imgWrap?.insertAdjacentHTML('beforeend', html);
						iterator++;
					};
					reader.readAsDataURL(f);
				}
			}
		});
	})

	// document.body.addEventListener('click', function (e) {
	// 	const target = <HTMLElement>e.target;

	// 	if (target.classList.contains('upload__img-close')) {
	// 		var file = target.parentElement.dataset.file;

	// 		for (var i = 0; i < imgArray.length; i++) {
	// 			if (imgArray[i].name === file) {
	// 				imgArray.splice(i, 1);
	// 				break;
	// 			}
	// 		}

	// 		target.parentElement.parentElement.remove();
	// 	}
	// });

	// // document.getElementById('form-reviewForSell').onsubmit = function (e) {
	// // 	e.preventDefault();
	// // 	console.log("OK submit");
	// // 	const fullname = <HTMLInputElement>document.getElementById('FullName');

	// // 	console.log(fullname.value === '');
	// // }
	// // $("#form-reviewForSell").submit(function (e) {
	// // 	if ($('#FullName').val() == '')
	// // 	{
	// // 		e.preventDefault();
	// // 		$('#ErrorName').removeAttr('hidden', 'hidden')
	// // 	}

	// // 	if ($('#Phone').val().replace('/ /g', '').length != 10 || $('#Phone').val() == '')
	// // 	{
	// // 		e.preventDefault();
	// // 		$('#ErrorPhone').removeAttr('hidden', 'hidden')
	// // 	}

	// // 	if ($('#FullName').val() != '' && $('#Phone').val().replace('/ /g', '').length == 10 && $('#Phone').val() != '') {
	// // 		var fd = new FormData($('#form-reviewForSell')[0]);
	// // 		fd.append("Content", $('#Content').val());
	// // 		e.preventDefault();
	// // 		$.ajax({
	// // 			type: 'POST',
	// // 			url: "@Url.Action("Comment", "Product")",
	// // 			data: fd,
	// // 			processData: false,
	// // 			contentType: false,
	// // 			success: function (res) {
	// // 				if (res.Result == 'Success') {
	// // 					alertify.success("Đánh giá sản phẩm thành công. Đánh giá của bạn sẽ được admin xác nhận trong thời gian sớm nhất.")
	// // 					//window.location.reload()
	// // 				}
	// // 			}
	// // 		})
	// // 	}
	// // })
}


function closeHandle(popup: any, callBack: Function = function () { }) {
	popup.classList.remove('is-showed');

	callBack(popup);
}

function popupHandleProductHavas(target: any, callBackClose: Function = function () { return "" }) {
	try {
		if (typeof target === 'string') {
			target = document.querySelector(`${target}`);
		}

		const clickHandleTarget = function (e: any) {
			e.preventDefault();
			popup.classList.add('is-showed');
		}

		target.removeEventListener('click', clickHandleTarget);
		target.addEventListener('click', clickHandleTarget);

		let popup = document.querySelector(`[data-popup-section="${target.getAttribute('data-popup')}"]`) || document.getElementById(`${target.getAttribute('data-popup')}`);

		if (!popup) {
			throw `Not found popup: ${target.getAttribute('data-popup')}`;
		}

		if (popup.classList.contains('initialized')) {
			return;
		}

		let closes = popup.querySelectorAll('.modal-close');

		const evtClose = function (e: any) {
			e.preventDefault();
			closeHandle(popup, callBackClose);
		}

		closes.forEach(function (close) {
			close.addEventListener('click', evtClose);
		})

		let cancels = popup.querySelectorAll('.cancel');
		cancels.forEach(function (cancel: HTMLElement) {
			cancel.addEventListener('click', function (e: any) {
				e.preventDefault();
				closeHandle(popup);
			})
		})

		popup.addEventListener('click', function (e) {
			e.stopPropagation();
			if (e.target === popup) {
				closeHandle(popup, callBackClose);
			}
		})

		popup.classList.add("initialized");
	}
	catch (err) {
		console.error(err);
	}
}

function initPopups() {
	let btnPopups = document.querySelectorAll('a[data-popup]');
	btnPopups.forEach(function (item) {
		popupHandleProductHavas(item, function (popup: HTMLElement) {
			return true;
		})
	})
}



function handlePayment() {
	const payemntSection = document.getElementById('payemntSection');
	if (payemntSection) {
		payemntSection.addEventListener('submit', function (event: any) {
			// Prevent form submission if the Enter key is pressed
			if (event.key === 'Enter') {
				event.preventDefault();
			}
		});

		const methodPayment = document.getElementsByName('radioMethodPayment');
		const btnSubmit = <HTMLElement>document.querySelector('#payemntSection .submit button');


		if (methodPayment.length > 0) {
			methodPayment.forEach(function (item: any) {
				if (item.checked) {
					btnSubmit.style.display = "";
				}
				item.addEventListener('change', function () {
					console.log(item.value);
					btnSubmit.style.display = "";
				})
			})
		}
	}
}

const Utils = {
	addClass: function (element: any, theClass: any) {
		element.classList.add(theClass);
	},

	removeClass: function (element: any, theClass: any) {
		element.classList.remove(theClass);
	},

	showMore: function (element: any, excerpt: any) {
		element.addEventListener("click", (event: any) => {
			event.preventDefault();

			const target = element;
			target.classList.toggle('is-showed');


			if (target.classList.contains('is-showed')) {
				target.textContent = target.getAttribute('data-textless');
				this.removeClass(excerpt, "excerpt-hidden");
				this.addClass(excerpt, "excerpt-visible");
			} else {
				target.textContent = target.getAttribute('data-textmore');
				this.removeClass(excerpt, "excerpt-visible");
				this.addClass(excerpt, "excerpt-hidden");
			}

			// const linkText = target.textContent.toLowerCase();
			// if (linkText == "show more") {
			// 	element.textContent = "Show less";
			// 	this.removeClass(excerpt, "excerpt-hidden");
			// 	this.addClass(excerpt, "excerpt-visible");
			// } else {
			// 	element.textContent = "Show more";
			// 	this.removeClass(excerpt, "excerpt-visible");
			// 	this.addClass(excerpt, "excerpt-hidden");
			// }
		});
	}
};

const ExcerptWidget = {
	showMore: function (showMoreLinksTarget: any, excerptTarget: any) {
		const showMoreLinks = document.querySelectorAll(showMoreLinksTarget);

		showMoreLinks.forEach(function (link) {

			const excerpt = link.previousElementSibling.querySelector(excerptTarget);
			console.log(excerpt.clientHeight);

			if (excerpt && excerpt.clientHeight > 20) {
				excerpt.classList.add('excerpt-hidden');
				Utils.showMore(link, excerpt);
			} else {
				link.style.display = "none";
			}

		});
	}
};

ExcerptWidget.showMore('.js-show-more', '.js-excerpt');

import { initPage } from "./libraries/pagination";
import { _base } from "./libraries/_base";
import { fancyboxLib } from "./libraries/fancybox-lib";
import { link } from "fs";

_base();
initPage();

declare var window: any;

const pageCommentProduct = function (numberValue: number = 4) {
	if (document.querySelector('.wrapper-pagination .pagination')) {
		const initPageCmts = new window.pagination({
			selector: '.wrapper-pagination .pagination',
			items: '.wrapper-posts .posts-item',
			page: numberValue,
			scroll: {
				id: "productReviews"
			}
		});
		return true;
	}
	return undefined;
}



function initSwiperSlider(target: HTMLElement) {
	const sw = new Swiper(target, {
		// zoom: true,

		// // autoplay: {
		// //     delay: 2400,
		// //     pauseOnMouseEnter: true,
		// //     disableOnInteraction: false,
		// // },
		// navigation: {
		// 	nextEl: ".swiper-button-next",
		// 	prevEl: ".swiper-button-prev",
		// },
		// pagination: {
		// 	el: ".swiper-pagination",
		// 	clickable: true,
		// },

		// effect: "fade",

		// zoom: true,
		slidesPerView: 1,

		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},
	})
	return sw;
}

function initSwiperThumb(target: HTMLElement) {
	// const sw = new Swiper(target, {
	// 	slidesPerView: "auto",
	// 	spaceBetween: 10,
	// })

	var sw = new Swiper(target, {
		spaceBetween: 10,
		slidesPerView: 4,
		watchSlidesVisibility: true,
		watchSlidesProgress: true,
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},
	});
	return sw;
}


function handleSwThumb() {
	const thumb = <HTMLElement>document.querySelector('.product-detail-section .swiper-btn__nav-detail .mySwiper');
	// const thumb = <HTMLElement>document.querySelector('.works-section .swiper-thumbSlider');
	const slider = <HTMLElement>document.querySelector('.product-detail-section .detail-infor__liveview .mySwiper2');
	if (thumb && slider) {
		const swSlider = initSwiperSlider(slider);
		const swThumb = initSwiperThumb(thumb);

		const lsThumb = thumb.querySelectorAll('.swiper-slide');
		window.delHandle(lsThumb, 'is-actived');
		lsThumb[0].classList.add('is-actived');

		swSlider.on('slideChange', function () {
			let activeIdx = swSlider.activeIndex;
			swThumb.slideTo(activeIdx);

			window.delHandle(lsThumb, 'is-actived');
			lsThumb[activeIdx].classList.add('is-actived');
		});

		swThumb.on('slideChange', function () {
			let activeIdx = swThumb.activeIndex;
			swSlider.slideTo(activeIdx);

			window.delHandle(lsThumb, 'is-actived');
			lsThumb[activeIdx].classList.add('is-actived');
		})

		lsThumb.forEach(function (itemThumb, idx) {
			itemThumb.addEventListener('click', function (e) {
				e.preventDefault();
				console.log(this, ' - idx: ', idx);
				swSlider.slideTo(idx);
			})
		})
	}
}

function swPurchaseServices() {
	if (document.querySelector('.purchase-service .swServices')) {
		return new Swiper('.purchase-service .swServices', {
			spaceBetween: 30,
			slidesPerView: 3,
			watchSlidesVisibility: true,
			watchSlidesProgress: true,
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			},
			breakpoints: {
				120: {
					slidesPerView: 1,
					spaceBetween: 30,
				},
				320: {
					slidesPerView: 1,
					spaceBetween: 30,
				},
				425: {
					slidesPerView: 1,
					spaceBetween: 30,
				},
				600: {
					slidesPerView: 1,
					spaceBetween: 30,
				},
				768: {
					slidesPerView: 2,
					spaceBetween: 30,
				},
				1024: {
					slidesPerView: 2,
					spaceBetween: 10,
				},
				1440: {
					slidesPerView: 3,
					spaceBetween: 30,
				}
			}
		});
	}
	return undefined;
}


function handleOption() {
	const opts = document.querySelectorAll('.product-reviews .wrapper-header .reviews-wrapper--more>ul>li');

	if (opts.length <= 0) {
		return false;
	}

	const list = <HTMLElement>document.querySelector('.wrapper-posts');
	const valueEmptry = list.dataset.emptry;

	const listContainer = document.createElement('div');
	listContainer.innerHTML = list.innerHTML;

	const listCustoms = document.createElement('div');
	listCustoms.classList.add('wrapper-posts-custom');
	listCustoms.classList.add('wrapper-posts');

	list.insertAdjacentElement('afterend', listCustoms);
	list.remove();

	const chooses = listContainer.querySelectorAll('.posts-item');

	var checkDefaultActive = false;
	opts.forEach(function (optItem: any, idxOpt: number) {
		const optionValue = optItem.dataset.option;
		var linkOpt = optItem.querySelector('a');

		if (optItem.classList.contains('is-actived')) {
			checkDefaultActive = true;
			window.delHandle(opts, 'is-actived', idxOpt);
			listCustoms.innerHTML = "";

			var checkComments = false;
			chooses.forEach(function (itemChoose: any) {
				if (itemChoose.dataset.star === optionValue) {
					checkComments = true;
					listCustoms.appendChild(itemChoose);
				}
			})

			if (checkComments === false) {
				listCustoms.innerHTML = `<p class="emptry">${valueEmptry}</p>`;
			}

			pageCommentProduct();
		}

		if (!linkOpt) {
			linkOpt = optItem;
		}

		linkOpt.addEventListener('click', function (e: any) {
			e.preventDefault();
			listCustoms.innerHTML = "";

			optItem.classList.add('is-actived');
			window.delHandle(opts, 'is-actived', idxOpt);
			var checkComments = false;

			chooses.forEach(function (itemChoose: any) {
				if (itemChoose.dataset.star === optionValue) {
					checkComments = true;
					listCustoms.appendChild(itemChoose);
				}
			})

			if (checkComments === false) {
				listCustoms.innerHTML = `<p class="emptry">${valueEmptry}</p>`;
			}

			pageCommentProduct();
		})
	})

	if (checkDefaultActive === false) {
		var checkComments = false;
		listCustoms.innerHTML = "";

		chooses.forEach(function (itemChoose: any) {
			checkComments = true;
			listCustoms.appendChild(itemChoose);
		})

		if (checkComments === false) {
			listCustoms.innerHTML = `<p class="emptry">${valueEmptry}</p>`;
		}

		pageCommentProduct();
	}
}

function popupNotifiesHandle() {
	let notifies = document.querySelectorAll('.popup-notify');
	notifies.forEach(function (item) {
		const closesItem = item.querySelectorAll('[data-type="close"]');
		console.log(closesItem);

		closesItem.forEach(function (close) {
			close.addEventListener('click', function (e) {
				e.preventDefault();
				item.classList.remove('is-showed');
			})
		})
	})
}

document.addEventListener('DOMContentLoaded', () => {
	ourTechSwiper();
	swiperDetailProducts();
	swiperWholesaleService();
	scrolltosilkfoam();
	// AOS.init();
	handleGlobal();
	fBtnArrow();
	headerUserHandle();
	footerHandle();
	// showSubMenuMobile();
	showContentDetails();
	endCheck();
	clickPaginationReleases();
	clickFancybox();
	popupHandle();
	// showContentDetails();
	swiperAboutBlog();
	toggleinput();
	submitDetailBlog();
	swiperDealerProduct();
	// popupHandle();
	navSelect();
	// ChangeButtonSwiper();

	activeIconFeatures();
	fMattressCollection();
	fDetailProducts();
	// fLoadSwiperWarranty();
	preventDefalutLink();
	// submitSearch();
	scrollMattress();
	handleOrderProduct();
	bannerHeight();
	heightProductDetails();

	popupHandleClick();

	checkBoxPoup();

	// Havas scripts
	fancyboxLib();

	handleQtyProduct();
	ImgUpload();
	initPopups();

	handlePayment();

	pageCommentProduct();
	handleSwThumb();
	swPurchaseServices();

	handleOption();
	popupNotifiesHandle();
})

